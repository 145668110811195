import React, { useState } from "react";
import { Box, Divider } from "../styles";
import { Hero, DocumentsNavigationBar, Documents } from "../component";
import SuggestedDocuments from "../component/SuggestedDocuments";

const DocumentsScreen = () => {
  const [search, setSearch] = useState<string>("");
  return (
    <Box>
      <DocumentsNavigationBar search={search} setSearch={setSearch} />
      <Box display={["none", "block"]} m="4rem 0 3rem 0">
        <Hero
          title="Nieuw document"
          fontSizeTitle="2rem"
          description="Nieuw voorgestelde documenten"
        />
      </Box>
      <SuggestedDocuments />
      <Divider display={["none", "block"]} mb="4rem" mt="1rem" />
      <Documents search={search} setSearch={setSearch} />
    </Box>
  );
};

export default DocumentsScreen;
