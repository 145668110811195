import React, { FC } from "react";
import documentIcons from "../lib/documentIcons";
import documentKinds from "../lib/documentKinds";
import { Box, Text } from "../styles";
import CreateDocumentForm from "./form/CreateDocumentForm";
import Modal from "./Modal";

interface Props {
  show: boolean;
  close: () => void;
  type: string;
}

const CreateDocumentModal: FC<Props> = ({ show, close, type }) => {
  return (
    <Modal close={close} show={show}>
      <Box p={["0", "2rem"]} width={["100%", "48rem"]}>
        <Text fontSize="2.4rem" fontWeight="600">
          {documentIcons[type]}
          <Box display="inline-block" ml={1} mr="5px">
            Nieuwe
          </Box>
          {documentKinds.find(({ key }) => key === type)?.value}
        </Text>
        <CreateDocumentForm close={close} type={type} />
      </Box>
    </Modal>
  );
};

export default CreateDocumentModal;
