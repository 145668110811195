import styled from "styled-components";
import {
  color,
  space,
  layout,
  flexbox,
  border,
  shadow,
  ColorProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  BorderProps,
  ShadowProps,
  position,
  PositionProps,
} from "styled-system";

interface Props
  extends ColorProps,
    SpaceProps,
    LayoutProps,
    FlexboxProps,
    BorderProps,
    ShadowProps,
    ColorProps,
    PositionProps {
  color?: string;
  space?: string;
  layout?: string;
  flexbox?: string;
  border?: string;
  shadow?: string;
}

const Box = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${shadow}
  ${position}
`;

export default Box;
