import React, { useEffect, useMemo } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  DocumentType,
  UpholsterersField,
  DeliveryStartFormErrorValues,
} from "../../lib/types";
import workInspectionActivitiesCheckFields from "../../lib/workInspectionActivitiesCheckFields";
import { Box, Divider, Grid, Label } from "../../styles";
import CheckBlock from "../CheckBlock";
import ClosedQuestions from "../ClosedQuestions";
import {
  FormButtons,
  FormDate,
  FormFieldArray,
  FormInput,
  FormTextarea,
} from "..";

const resolver: Resolver<DocumentType> = async (values) => {
  const errors: DeliveryStartFormErrorValues = {};
  if (!values.createdByName) {
    errors.createdByName = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.customerName) {
    errors.customerName = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.projectNumber) {
    errors.projectNumber = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.presentUpholsterers) {
    errors.presentUpholsterers = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.recordingDate) {
    errors.recordingDate = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (values.recalculationUpholsterers && !values.recalculationExecutedWork) {
    errors.recalculationExecutedWork = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (values.recalculationUpholsterers && !values.recalculationUsedMaterials) {
    errors.recalculationUsedMaterials = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  return {
    values,
    errors,
  };
};

const WorkInspectionStartForm = () => {
  const {
    doc,
    saveDocument,
  }: {
    doc: any;
    saveDocument: (d: any, e: any) => void;
  } = useDocument();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm<DocumentType>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        createdByName: doc.createdByName,
        customerName: doc.customerName,
        presentUpholsterers: doc.presentUpholsterers,
        projectNumber: doc.projectNumber,
        recordingDate: doc.recordingDate?.toDate?.(),
        demolish: doc.demolish,
        equalize: doc.equalize,
        layingfloors: doc.layingfloors,
        installingSkirtingBoard: doc.installingSkirtingBoard,
        installingRails: doc.installingRails,
        installingIndoorSunEffect: doc.installingIndoorSunEffect,
        workInspectionActivitiesOther: doc.workInspectionActivitiesOther,
        recalculation: doc.recalculation,
        recalculationExecutedWork: doc.recalculationExecutedWork,
        recalculationSpentHours: doc.recalculationSpentHours,
        recalculationUsedMaterials: doc.recalculationUsedMaterials,
        recalculationUpholsterers: doc.recalculationUpholsterers?.map(
          (uphosterer: UpholsterersField) => ({
            ...uphosterer,
            date: uphosterer.date?.toDate?.(),
          })
        ),
        additionalWork: doc.additionalWork,
        additionalWorkExecutedWork: doc.additionalWorkExecutedWork,
        additionalWorkUsedMaterials: doc.additionalWorkUsedMaterials,
        additionalWorkJobGivenBy: doc.additionalWorkJobGivenBy,
        additionalWorkSpentHours: doc.additionalWorkSpentHours,
        additionalWorkUpholsterers: doc.additionalWorkUpholsterers?.map(
          (uphosterer: UpholsterersField) => ({
            ...uphosterer,
            date: uphosterer.date?.toDate?.(),
          })
        ),
      };
    }, [doc]),
  });

  const values: any = (name: any) => ({
    value: getValues(name),
    name,
    control,
  });

  useEffect(() => {
    watch();
  }, [watch]);

  return (
    <form onSubmit={preventDefault}>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
        <FormInput
          label="Opgenomen door"
          error={errors?.createdByName?.message}
          {...register("createdByName")}
        />
        <FormInput
          label="Naam klant"
          error={errors?.customerName?.message}
          {...register("customerName")}
        />
        <FormInput
          label="Projectnummer"
          error={errors?.projectNumber?.message}
          {...register("projectNumber")}
        />
        <FormInput
          label="Aanwezige stoffeerders"
          error={errors?.presentUpholsterers?.message}
          {...register("presentUpholsterers")}
        />
        <Controller
          control={control}
          name="recordingDate"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value as Date}
              error={errors?.recordingDate?.message}
              label="Datum opname"
            />
          )}
        />
      </Grid>
      <Divider display={["none", "block"]} my="4rem" />
      <Box my={2}>
        <Label>
          Uit te voeren werkzaamheden
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr"]}
            gridColumnGap={2}
            gridRowGap={1}
            mt={1}
          >
            {workInspectionActivitiesCheckFields.map(({ key, value }) => (
              <CheckBlock key={key} label={value} {...values(key)} />
            ))}
          </Grid>
        </Label>
      </Box>
      <FormTextarea
        label="Anders, namelijk"
        {...register("workInspectionActivitiesOther")}
      />
      <Divider display={["none", "block"]} my="4rem" />
      <ClosedQuestions
        title="Is de opdracht op nacalculatie?"
        {...values("recalculation")}
      >
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2} mb={2}>
          <FormTextarea
            label="Uitgevoerde werkzaamheden"
            error={errors?.recalculationExecutedWork?.message}
            {...register("recalculationExecutedWork")}
          />
          <FormTextarea
            label="Gebruikte materialen"
            error={errors?.recalculationUsedMaterials?.message}
            {...register("recalculationUsedMaterials")}
          />
          <FormInput
            label="Bestede uren"
            {...register("recalculationSpentHours")}
          />
        </Grid>
        <FormFieldArray
          control={control}
          register={register}
          name="recalculationUpholsterers"
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is er meerwerk uitgevoerd?"
        {...values("additionalWork")}
      >
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2} mb={2}>
          <FormTextarea
            label="Uitgevoerde werkzaamheden"
            error={errors?.additionalWorkExecutedWork?.message}
            {...register("additionalWorkExecutedWork")}
          />
          <FormTextarea
            label="Gebruikte materialen"
            error={errors?.additionalWorkUsedMaterials?.message}
            {...register("additionalWorkUsedMaterials")}
          />
          <FormInput
            label="Opdacht gekregen van"
            {...register("additionalWorkJobGivenBy")}
          />
          <FormInput
            label="Bestede uren"
            {...register("additionalWorkSpentHours")}
          />
        </Grid>
        <FormFieldArray
          control={control}
          register={register}
          name="additionalWorkUpholsterers"
        />
      </ClosedQuestions>
      <FormButtons
        onNext={handleSubmit((args) => saveDocument(args, 1))}
        onSave={handleSubmit((args) => saveDocument(args, 0))}
      />
    </form>
  );
};

export default WorkInspectionStartForm;
