import React from "react";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import LoginScreen from "../screen/LoginScreen";
import { ReactComponent as FormsIcon } from "../assets/icon/forms.svg";
import { Box } from "../styles";
import RegisterScreen from "../screen/RegisterScreen";
import { devices } from "../lib/sizes";
import ForgotPasswordScreen from "../screen/ForgotPasswordScreen";

const HomeLink = styled(Link)`
  display: inline-block;
  position: fixed;
  top: 4rem;
  left: 4rem;

  @media ${devices.tablet} {
    top: 3rem;
    left: 3rem;
  }
  @media ${devices.mobile} {
    display: none;
  }
`;

const AuthLayout = () => {
  return (
    <Box flexGrow={1}>
      <HomeLink to="/">
        <FormsIcon />
      </HomeLink>
      <Box
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        py={[2, 3, 4]}
      >
        <Box width={[440, 460, 480]} maxWidth="100%" px={[2, 3, 4]}>
          <Routes>
            <Route path="" element={<LoginScreen />} />
            <Route path="/signup" element={<RegisterScreen />} />
            <Route path="/forgot" element={<ForgotPasswordScreen />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthLayout;
