import React, { FC } from "react";
import styled from "styled-components";
import { Block, Box, Button, Input } from "../styles";
import { ReactComponent as LogoutIcon } from "../assets/icon/logout.svg";
import { ReactComponent as SearchIcon } from "../assets/icon/search.svg";
import UserThumbnail from "./UserThumbnail";
import { useAuth } from "../context/AuthContext";

const SearchBlock = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.5rem;

  svg {
    margin: 0;
  }

  @media screen and (max-width: 40em) {
    left: auto;
    right: 1.5rem;
  }
`;

interface Props {
  setSearch: (value: string) => void;
  search: string;
}

const DocumentsNavigationBar: FC<Props> = ({ search, setSearch }) => {
  const { logout } = useAuth();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  return (
    <Block height={80} alignItems="center" flexDirection="row">
      <Box p={["0 1rem", "0 2rem"]} borderRight="1px solid #ECEEEF">
        <UserThumbnail />
      </Box>
      <Box flexGrow={1} pl={["0 1rem", "0 2rem"]}>
        <SearchBlock variant="empty">
          <SearchIcon />
        </SearchBlock>
        <Input
          border="none"
          placeholder="Zoek op document"
          pl={[15, 45]}
          onChange={onChange}
          value={search}
        />
      </Box>
      <Box p={["0 1rem", "0 2rem"]} borderLeft="1px solid #ECEEEF">
        <Button variant="icon" width={40} height={40} onClick={logout}>
          <LogoutIcon />
        </Button>
      </Box>
    </Block>
  );
};

export default DocumentsNavigationBar;
