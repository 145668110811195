import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import {
  curtainMountedOnOptions,
  curtainMountedTimeOptions,
  curtainNewRailOptions,
  curtainPleatTypeOptions,
  curtainSetOrPieceOptions,
  hasCurtainSupportDistanceOptions,
} from "../../lib/curtainOptions";
import { RoomCurtainType } from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Button, Grid } from "../../styles";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const RoomCurtainForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<RoomCurtainType>({
    defaultValues: useMemo(() => {
      return {
        curtainClearWidth: room.curtainClearWidth,
        curtainClearHeight: room.curtainClearHeight,
        curtainPieceOrSet: room.curtainPieceOrSet,
        curtainNewRail: room.curtainNewRail,
        curtainMountedOn: room.curtainMountedOn,
        curtainMountedTime: room.curtainMountedTime,
        curtainPleatType: room.curtainPleatType,
        curtainHeadSize: room.curtainHeadSize,
        hasCurtainSupportDistance: room.hasCurtainSupportDistance,
        curtainSupportDistance: room.curtainSupportDistance,
        curtainComment: room.curtainComment,
        curtainImages: room.curtainImages,
      };
    }, [room]),
  });

  const onSubmit = async (args: RoomCurtainType) => {
    const curtainImages = await updateAndGetFiles(
      room.curtainImages,
      args.curtainImages
    );
    const onSuccess = await saveRoom({ ...args, curtainImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("curtainImages") || [],
      control,
      setValue,
      ...register("curtainImages"),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Stuk of stel"
              options={curtainSetOrPieceOptions}
              selected={!!getValues("curtainPieceOrSet")}
              {...register("curtainPieceOrSet")}
            />
            <FormSelect
              label="Nieuwe rail"
              options={curtainNewRailOptions}
              selected={!!getValues("curtainNewRail")}
              {...register("curtainNewRail")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Montage aan"
              options={curtainMountedOnOptions}
              selected={!!getValues("curtainMountedOn")}
              {...register("curtainMountedOn")}
            />
            <FormSelect
              label="Montage"
              options={curtainMountedTimeOptions}
              selected={!!getValues("curtainMountedTime")}
              {...register("curtainMountedTime")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Plooi"
              options={curtainPleatTypeOptions}
              selected={!!getValues("curtainPleatType")}
              {...register("curtainPleatType")}
            />
            <FormInput label="Hoofdje in cm" {...register("curtainHeadSize")} />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Afstandsteun"
              options={hasCurtainSupportDistanceOptions}
              selected={!!getValues("hasCurtainSupportDistance")}
              {...register("hasCurtainSupportDistance")}
            />
            <FormInput
              label="Afstand aantal cm"
              {...register("curtainSupportDistance")}
            />
          </Grid>
        </Box>
        <Box width={["100%", "50%"]} mb={2}>
          <Grid gridTemplateColumns="1fr 1fr" gridGap={2}>
            <FormInput
              label="Klare breedte (cm)"
              {...register("curtainClearWidth")}
            />
            <FormInput
              label="Klare lengte (cm)"
              {...register("curtainClearHeight")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <FormTextarea label="Toelichting" {...register("curtainComment")} />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-gordijnen`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default RoomCurtainForm;
