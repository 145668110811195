import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import { RoomApplyCoverTypes } from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Button, Grid } from "../../styles";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const ApplyCoverForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    watch,
    formState: { isDirty },
    reset,
  } = useForm<RoomApplyCoverTypes>({
    defaultValues: useMemo(() => {
      return {
        floorSurface: room.floorSurface,
        coverProductOther: room.coverProductOther,
        coverProduct: room.coverProduct,
        coverLength: room.coverLength,
        coverWidth: room.coverWidth,
        coverComment: room.coverComment,
        applyCoverImages: room.applyCoverImages,
      };
    }, [room]),
  });

  const coverProducts: { key: string; value: string }[] = [
    { key: "PVC_STRIPS", value: "PVC Stroken" },
    { key: "PVC_TILES", value: "PVC  Tegels" },
    { key: "PVC_SHEETS", value: "PVC Banen" },
    { key: "CARPET_TILES", value: "Tapijttegel" },
    { key: "CARPET_SHEETS", value: "Tapijt Banen" },
    { key: "MARMOLEUM_SHEETS", value: "Marmoleum Banen" },
    { key: "MARMOLEUM_TILES", value: "Marmoleum Tegels" },
    { key: "OTHER", value: "Anders (toelichting vullen)" },
  ];

  const onSubmit = async (args: RoomApplyCoverTypes) => {
    const applyCoverImages = await updateAndGetFiles(
      room.applyCoverImages,
      args.applyCoverImages
    );
    const onSuccess = await saveRoom({ ...args, applyCoverImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("applyCoverImages") || [],
      control,
      setValue,
      ...register("applyCoverImages"),
    };
  };
  const coverProduct = watch("coverProduct");
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormInput
              label="Vloer oppervlakte"
              {...register("floorSurface")}
            />
            <Box>
              <FormSelect
                label="Product"
                options={coverProducts}
                selected={!!coverProduct}
                {...register("coverProduct")}
              />
              {coverProduct === "OTHER" && (
                <Box mt={2}>
                  <FormInput
                    label="Toelichting"
                    {...register("coverProductOther")}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          <Box width={["100%", "50%"]} mt={2}>
            <Grid gridTemplateColumns="1fr 1fr" gridGap={2}>
              <FormInput label="Lengte" {...register("coverLength")} />
              <FormInput label="Breedte" {...register("coverWidth")} />
            </Grid>
          </Box>
        </Box>
        <Box mb={2}>
          <FormTextarea label="Toelichting" {...register("coverComment")} />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-bedekking`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default ApplyCoverForm;
