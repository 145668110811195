import React from "react";
import styled from "styled-components";
import { Box } from "../styles";
import { ReactComponent as Logo } from "../assets/icon/logo.svg";

const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  svg {
    position: absolute;
    z-index: 0;
    left: -1.75rem;
    top: -3.5rem;
    height: 12rem;
    width: 12rem;
  }

  div {
    position: absolute;
    width: 100%;
    height: 12px;
    z-index: 1;
    border-radius: 4px;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
    background-color: ${({ theme }) => theme.colors.white};
  }
  div:nth-child(1) {
    top: 8px;
    left: 0;
    animation-delay: 0s;
  }
  div:nth-child(2) {
    top: 32px;
    left: 0;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    top: 56px;
    left: 0;
    animation-delay: -0.8s;
  }

  @keyframes lds-grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

const Spinner = () => {
  return (
    <Box
      width="100%"
      height="100%"
      flexGrow={1}
      justifyContent="center"
      alignItems="center"
    >
      <SpinnerContainer>
        <div />
        <div />
        <div />
        <Logo />
      </SpinnerContainer>
    </Box>
  );
};

export default Spinner;
