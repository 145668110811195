import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import { RoomWindowCoveringsType } from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import {
  hasWindowSupportDistanceOptions,
  windowFrameOptions,
  windowMountOptions,
  windowPackageOptions,
  windowProductOptions,
  windowSideOperationOptions,
} from "../../lib/windowCoveringOptions";
import { Box, Button, Grid } from "../../styles";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const RoomWindowCoveringsForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<RoomWindowCoveringsType>({
    defaultValues: useMemo(() => {
      return {
        windowClearWidth: room.windowClearWidth,
        windowClearHeight: room.windowClearHeight,
        windowProduct: room.windowProduct,
        windowMount: room.windowMount,
        windowPackage: room.windowPackage,
        windowLengthRopeRod: room.windowLengthRopeRod,
        windowSideOperation: room.windowSideOperation,
        windowFrame: room.windowFrame,
        hasWindowSupportDistance: room.hasWindowSupportDistance,
        windowSupportDistance: room.windowSupportDistance,
        windowSideGuide: room.windowSideGuide,
        windowComment: room.windowComment,
        coveringsImages: room.coveringsImages,
      };
    }, [room]),
  });

  const onSubmit = async (args: RoomWindowCoveringsType) => {
    const coveringsImages = await updateAndGetFiles(
      room.coveringsImages,
      args.coveringsImages
    );
    const onSuccess = await saveRoom({ ...args, coveringsImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("coveringsImages") || [],
      control,
      setValue,
      ...register("coveringsImages"),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Product"
              options={windowProductOptions}
              selected={!!getValues("windowProduct")}
              {...register("windowProduct")}
            />
            <FormSelect
              label="Montage"
              options={windowMountOptions}
              selected={!!getValues("windowMount")}
              {...register("windowMount")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Pakket L/R/M"
              options={windowPackageOptions}
              selected={!!getValues("windowPackage")}
              {...register("windowPackage")}
            />
            <FormInput
              label="Lengte koort/stang"
              {...register("windowLengthRopeRod")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Kant bediening"
              options={windowSideOperationOptions}
              selected={!!getValues("windowSideOperation")}
              {...register("windowSideOperation")}
            />
            <FormSelect
              label="Kozijn"
              options={windowFrameOptions}
              selected={!!getValues("windowFrame")}
              {...register("windowFrame")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormSelect
              label="Afstandsteun"
              options={hasWindowSupportDistanceOptions}
              selected={!!getValues("hasWindowSupportDistance")}
              {...register("hasWindowSupportDistance")}
            />
            <FormInput
              label="Afstand aantal cm"
              {...register("windowSupportDistance")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormInput label="Zijgeleiding" {...register("windowSideGuide")} />
            <Grid gridTemplateColumns="1fr 1fr" gridGap={2}>
              <FormInput
                label="Klare breedte (cm)"
                {...register("windowClearWidth")}
              />
              <FormInput
                label="Klare lengte (cm)"
                {...register("windowClearHeight")}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <FormTextarea label="Toelichting" {...register("windowComment")} />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-raambekleding`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default RoomWindowCoveringsForm;
