import React, { useEffect, useMemo } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  WorkInspectionStartErrorValues,
  WorkInspectionStartFormValues,
} from "../../lib/types";
import workInspectionActivitiesCheckFields from "../../lib/workInspectionActivitiesCheckFields";
import { Box, Divider, Grid, Label } from "../../styles";
import CheckBlock from "../CheckBlock";
import FormButtons from "./FormButtons";
import FormDate from "./FormDate";
import FormInput from "./FormInput";
import FormTextarea from "./FormTextarea";

const resolver: Resolver<WorkInspectionStartFormValues> = async (values) => {
  const errors: WorkInspectionStartErrorValues = {};
  if (!values.createdByName) {
    errors.createdByName = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.customerName) {
    errors.customerName = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.projectNumber) {
    errors.projectNumber = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.presentUpholsterers) {
    errors.presentUpholsterers = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.recordingDate) {
    errors.recordingDate = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  return {
    values,
    errors,
  };
};

const WorkInspectionStartForm = () => {
  const {
    doc,
    saveDocument,
  }: {
    doc: any;
    saveDocument: (d: any, e: any) => void;
  } = useDocument();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm<WorkInspectionStartFormValues>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        createdByName: doc.createdByName,
        customerName: doc.customerName,
        projectNumber: doc.projectNumber,
        presentUpholsterers: doc.presentUpholsterers,
        recordingDate: doc.recordingDate?.toDate?.(),
        demolish: doc.demolish,
        equalize: doc.equalize,
        layingfloors: doc.layingfloors,
        installingSkirtingBoard: doc.installingSkirtingBoard,
        installingRails: doc.installingRails,
        installingIndoorSunEffect: doc.installingIndoorSunEffect,
        workInspectionActivitiesOther: doc.workInspectionActivitiesOther,
      };
    }, [doc]),
  });

  useEffect(() => {
    watch();
  }, [watch]);

  const values: any = (name: any) => ({
    value: getValues(name),
    name,
    control,
  });

  return (
    <form onSubmit={preventDefault}>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
        <FormInput
          label="Opgenomen door"
          error={errors?.createdByName?.message}
          {...register("createdByName")}
        />
        <FormInput
          label="Naam klant"
          error={errors?.customerName?.message}
          {...register("customerName")}
        />
        <FormInput
          label="Projectnummer"
          error={errors?.projectNumber?.message}
          {...register("projectNumber")}
        />
        <FormInput
          label="Aanwezige stoffeerders"
          error={errors?.presentUpholsterers?.message}
          {...register("presentUpholsterers")}
        />
        <Controller
          control={control}
          name="recordingDate"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value as Date}
              error={errors?.recordingDate?.message}
              label="Datum opname"
            />
          )}
        />
      </Grid>
      <Divider display={["none", "block"]} my="4rem" />
      <Box my={2}>
        <Label>
          Uit te voeren werkzaamheden
          <Grid
            gridTemplateColumns={["1fr", "1fr 1fr"]}
            gridColumnGap={2}
            gridRowGap={1}
            mt={1}
          >
            {workInspectionActivitiesCheckFields.map(({ key, value }) => (
              <CheckBlock key={key} label={value} {...values(key)} />
            ))}
          </Grid>
        </Label>
      </Box>
      <FormTextarea
        label="Anders, namelijk"
        {...register("workInspectionActivitiesOther")}
      />
      <FormButtons
        onNext={handleSubmit((args) => saveDocument(args, 1))}
        onSave={handleSubmit((args) => saveDocument(args, 0))}
      />
    </form>
  );
};

export default WorkInspectionStartForm;
