import styled from "styled-components";
import Box from "./Box";

const FlexWrap = styled(Box)`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;

  @media screen and (max-width: 60rem) {
    gap: 1rem;
  }
`;

export default FlexWrap;
