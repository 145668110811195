import styled from "styled-components";
import { space, SpaceProps } from "styled-system";

type Props = SpaceProps;

const DivideContainer = styled.div<Props>`
  padding: 0 2rem;
  border-right: 1px solid #eceeef;
  height: 3rem;
  display: flex;
  align-items: center;
  ${space}
`;

export default DivideContainer;
