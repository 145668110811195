import React, { FC, useMemo } from "react";
import { Controller, useForm, Resolver } from "react-hook-form";
import { useParams } from "react-router-dom";
import roomKindOptions from "../lib/roomKindOptions";
import { RoomKindKey, RoomKindOptionField, RoomTypes } from "../lib/types";
import { Block, Box, Button, Text } from "../styles";
import FormCheckbox from "./form/FormCheckbox";
import FormInput from "./form/FormInput";
import Modal from "./Modal";
import { firestore } from "../firebase/firebaseSetup";

interface Props {
  show: boolean;
  room: RoomTypes;
  close: () => void;
  onSuccess: (room: RoomTypes) => void;
}

type ErrorValues = {
  name?: object;
};

const resolver: Resolver<RoomTypes> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.name) {
    errors.name = { type: "required", message: "Dit veld is verplicht." };
  }
  return {
    values,
    errors,
  };
};

const UpdateRoomModal: FC<Props> = ({ show, close, onSuccess, room }) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<RoomTypes>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        name: room.name,
        floors: room.floors,
        floorDemolish: room.floorDemolish,
        floorEqualize: room.floorEqualize,
        floorLay: room.floorLay,
        windows: room.windows,
        windowRails: room.windowRails,
        windowCurtains: room.windowCurtains,
        windowNetCurtains: room.windowNetCurtains,
        windowIndoorBlinds: room.windowIndoorBlinds,
        other: room.other,
        otherRails: room.otherRails,
        otherWall: room.otherWall,
        otherStairs: room.otherStairs,
        otherComment: room.otherComment,
      };
    }, [room]),
  });
  const { id } = useParams();

  const watchRoom = watch();

  const updateRoom = async (args: RoomTypes) => {
    await firestore()
      .collection("documents")
      .doc(id)
      .collection("rooms")
      .doc(room.id)
      .set(
        {
          ...args,
          updatedAt: firestore.FieldValue.serverTimestamp(),
        },
        {
          merge: true,
        }
      );
    onSuccess({ ...room, ...args });
  };

  return (
    <Modal close={close} show={show}>
      <form onSubmit={handleSubmit(updateRoom)}>
        <Box p={["2rem", "4rem"]}>
          <Text fontSize="2.4rem" fontWeight="600">
            Ruimte aanpassen
          </Text>
          <Box my={4}>
            <FormInput
              label="Ruimtenaam"
              {...register("name")}
              error={errors?.name?.message}
            />
          </Box>
          <Box mb={2}>
            <Text fontSize="2.4rem" fontWeight="600">
              Uit te voeren werkzaamheden
            </Text>
            <Text fontSize="1.6rem" color="error">
              Elke selectie die u nu maakt heeft gevolgen op de volgende stappen
            </Text>
          </Box>
          <Box mb={4}>
            {roomKindOptions.map(({ kind, options }) => (
              <Box key={kind.key} mb={1}>
                <Block
                  bg={watchRoom[kind.key as RoomKindKey] ? "primaryLight" : ""}
                  borderColor={
                    watchRoom[kind.key as RoomKindKey] ? "primary" : ""
                  }
                >
                  <Box flexDirection="row" height="7rem" alignItems="center">
                    <Box
                      width={160}
                      borderRight="1px solid #ECEEEF"
                      mr={4}
                      px={2}
                    >
                      <Controller
                        name={kind.key as RoomKindKey}
                        control={control}
                        render={(props) => {
                          return (
                            <FormCheckbox
                              label={kind.value}
                              {...props.field}
                              style={{ fontSize: 14, fontWeight: "600" }}
                            />
                          );
                        }}
                      />
                    </Box>
                    <Box
                      flexDirection="row"
                      opacity={watchRoom[kind.key as RoomKindKey] ? 1 : 0.2}
                      style={{
                        pointerEvents: watchRoom[kind.key as RoomKindKey]
                          ? undefined
                          : "none",
                      }}
                    >
                      {options.map(({ key, value }) => (
                        <Box key={key} mr={2}>
                          <Controller
                            name={key as RoomKindOptionField}
                            control={control}
                            render={(props) => {
                              return (
                                <FormCheckbox
                                  label={value}
                                  {...props.field}
                                  style={{ fontSize: 14 }}
                                />
                              );
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Block>
              </Box>
            ))}
          </Box>
          <Button variant="primary" width={180} ml="auto">
            Ruimte aanpassen
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default UpdateRoomModal;
