import {
  color,
  space,
  fontSize,
  fontWeight,
  ColorProps,
  SpaceProps,
  FontSizeProps,
  FontWeightProps,
  textAlign,
  TextAlignProps,
  layout,
  LayoutProps,
} from "styled-system";
import styled from "styled-components";

interface Props
  extends ColorProps,
    SpaceProps,
    FontSizeProps,
    FontWeightProps,
    TextAlignProps,
    LayoutProps {
  color?: string;
}

const Text = styled("span")<Props>(
  {
    fontWeight: "400",
    fontSize: "1.6rem",
  },
  color,
  space,
  fontSize,
  fontWeight,
  textAlign,
  layout
);

export default Text;
