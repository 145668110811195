import styled from "styled-components";
import {
  color,
  ColorProps,
  border,
  BorderProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
} from "styled-system";

interface Props extends SpaceProps, BorderProps, ColorProps, LayoutProps {
  space?: string;
  border?: string;
  color?: string;
  error?: string;
  onChange?: (e: any) => void;
}

const Input = styled.input<Props>`
  border: 1px solid;
  border-color: ${(props) =>
    props.error ? props.theme.colors.error : props.theme.colors.secondary};
  background-color: ${(props) =>
    props.error ? props.theme.colors.errorLight : "#fff"};
  height: 5rem;
  border-radius: 1rem;
  width: 100%;
  padding-left: 1.6rem;
  font-size: 1.6rem;

  &::placeholder {
    color: ${({ theme }) => theme.colors.disabled};
  }

  &:focus {
    border-color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.primary};
    background-color: ${(props) =>
      props.error ? props.theme.colors.errorLight : "#e8f1ff"};
  }

  ${space}
  ${border}
  ${color}
  ${layout}
`;

export default Input;
