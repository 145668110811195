import styled from "styled-components";
import { Link } from "react-router-dom";
import { fontSize, FontSizeProps } from "styled-system";

interface Props extends FontSizeProps {
  fontSize?: string;
}

const NavLink = styled(Link)<Props>`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-size: 1.4rem;

  ${fontSize}

  &:hover {
    text-decoration: underline;
  }
`;

export default NavLink;
