import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../assets/icon/check.svg";

const Animated = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 2rem;
    height: 2rem;

    path {
      stroke-dasharray: 30;
      stroke-dashoffset: 30;
      animation: dash 1s ease-in-out;
    }

    @keyframes dash {
      to {
        stroke-dashoffset: 0;
      }
    }
  }
`;

interface Props {
  hide: () => void;
}

const AnimatedCheck: FC<Props> = ({ hide }) => {
  useEffect(() => {
    setTimeout(hide, 2000);
  }, []);
  return (
    <Animated>
      <CheckIcon />
    </Animated>
  );
};

export default AnimatedCheck;
