import React, { useState } from "react";
import { Hero, ForgotPasswordForm } from "../component";
import { Box, NavLink, Text } from "../styles";

const ForgotPasswordScreen = () => {
  const [success, setSuccess] = useState<boolean>(false);
  return (
    <Box width={400} maxWidth="100%">
      {success ? (
        <Box mb="5rem">
          <Text fontSize="2.4rem" fontWeight="600" textAlign="center">
            E-mail is verstuurd.
          </Text>
          <Text fontSize="1.6rem" color="disabled" textAlign="center">
            We versturen je een e-mail waarin verdere instructies staan om je
            wachtwoord aan te passen. Dit kan een paar minuten duren en mogelijk
            in je spam folder belanden.
          </Text>
        </Box>
      ) : (
        <>
          <Box mb="5rem">
            <Hero
              title="Wachtwoord vergeten?"
              description="Vul hieronder uw e-mailadres in en we sturen u een verificatiecode om uw wachtwoord opnieuw in te stellen."
            />
          </Box>
          <ForgotPasswordForm onSuccess={() => setSuccess(true)} />
        </>
      )}
      <Box flexDirection="row" justifyContent="center">
        <NavLink to="/">Terug naar login</NavLink>
      </Box>
    </Box>
  );
};

export default ForgotPasswordScreen;
