import React from "react";
import styled from "styled-components";

const SuccessCenter = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  margin-top: 6rem;

  &:not(:last-child) {
    margin-bottom: 0.3em;
  }
`;

const AnimatedCircle = styled.div`
  @keyframes scaleUpCircle1 {
    0% {
      transform: translateX(-50%) scale(1);
      opacity: 1;
    }
    70% {
      opacity: 1;
      transform: translateX(-50%) scale(1.5);
    }
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 0;
    }
  }

  width: 96px;
  height: 96px;
  position: absolute;
  left: 50%;
  top: 0;
  border-radius: 999px;
  background-color: rgba(66, 215, 160, 0.1);
  animation: scaleUpCircle1 3s cubic-bezier(0.55, -0.03, 0.25, 1) infinite;
`;

const AnimatedMiddle = styled(AnimatedCircle)`
  @keyframes scaleUpCircle2 {
    0% {
      transform: translateX(-50%) scale(1);
      opacity: 1;
    }
    70% {
      opacity: 1;
      transform: translateX(-50%) scale(2);
    }
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 0;
    }
  }

  animation-name: scaleUpCircle2;
`;

const AnimatedOuter = styled(AnimatedCircle)`
  @keyframes scaleUpCircle3 {
    0% {
      transform: translateX(-50%) scale(1);
      opacity: 1;
    }
    70% {
      transform: translateX(-50%) scale(2.5);
      opacity: 1;
    }
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 0;
    }
  }

  animation-name: scaleUpCircle3;
`;

const SuccessIcon = styled.div`
  @keyframes showSuccess {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }

  display: inline-block;
  width: 8em;
  height: 8em;
  font-size: 12px;
  border-radius: 50%;
  border: 4px solid #42d7a0;
  background-color: #42d7a0;
  position: relative;
  overflow: hidden;
  transform-origin: center;
  animation: showSuccess 180ms ease-in-out;
  transform: scale(1);
  margin-bottom: 7.5em;
`;

const SuccessIconTip = styled.div`
  @keyframes tipInPlace {
    from {
      width: 0em;
      top: 0em;
      left: -1.6em;
    }
    to {
      width: 2.4em;
      top: 4.3em;
      left: 1.4em;
      visibility: visible;
    }
  }

  display: block;
  position: absolute;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
  width: 2.4em;
  top: 4.3em;
  left: 1.4em;
  transform: rotate(45deg);
  animation: tipInPlace 300ms ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 180ms;
  visibility: hidden;
`;

const SuccessIconLong = styled.div`
  @keyframes longInPlace {
    from {
      width: 0em;
      top: 5.1em;
      left: 3.2em;
    }
    to {
      width: 4em;
      top: 3.7em;
      left: 2.75em;
      visibility: visible;
    }
  }
  display: block;
  position: absolute;
  height: 4px;
  background-color: #fff;
  border-radius: 10px;
  width: 4em;
  transform: rotate(-45deg);
  top: 3.7em;
  left: 2.75em;
  animation: longInPlace 400ms ease-in-out;
  animation-fill-mode: forwards;
  visibility: hidden;
  animation-delay: 300ms + 140ms;
`;

const AnimatedCheckCirlce = () => {
  return (
    <SuccessCenter>
      <AnimatedCircle />
      <AnimatedMiddle />
      <AnimatedOuter />
      <SuccessIcon>
        <SuccessIconTip />
        <SuccessIconLong />
      </SuccessIcon>
    </SuccessCenter>
  );
};

export default AnimatedCheckCirlce;
