import React, { FC } from "react";
import { Box, Text } from "../styles";

interface Props {
  title: string;
  description: string;
  fontSizeTitle?: string;
  fontSizeDescription?: string;
}

const Hero: FC<Props> = ({
  title,
  description,
  fontSizeTitle = "2.4rem",
  fontSizeDescription = "1.6rem",
  ...props
}) => {
  return (
    <Box {...props}>
      <Text fontSize={fontSizeTitle} fontWeight="600" textAlign="left">
        {title}
      </Text>
      <Text
        fontSize={fontSizeDescription}
        display={["none", "inline-block"]}
        color="disabled"
        textAlign="left"
      >
        {description}
      </Text>
    </Box>
  );
};

export default Hero;
