import styled from "styled-components";
import { position } from "styled-system";
import Box from "./Box";

const AbsoluteShadedBox = styled(Box)`
  position: absolute;
  right: 0.5rem;
  top: 0;
  width: 4rem !important;
  height: 4rem !important;
  width: 60px;
  height: calc(100% - 10px);
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  );

  ${position}
`;

export default AbsoluteShadedBox;
