import React, { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentBar, ShareDocumentModal, Spinner } from "../component";
import Forms from "../component/form/Forms";
import Stepper from "../component/Stepper";
import { useApp } from "../context/AppContext";
import { DocumentContext } from "../context/DocumentContext";
import { firestore } from "../firebase/firebaseSetup";
import documentSteps from "../lib/documentSteps";
import previewImages from "../lib/previewImages";
import { DocumentTypes, FormValues } from "../lib/types";
import { Box } from "../styles";

const DocumentScreen = () => {
  const { id }: any = useParams();
  const { createImage } = useApp();

  const [doc, setDocument] = useState<any | null>(null);
  const [mounted, setMounted] = useState<boolean>(true);
  const [sendDocumentModal, showSendDocumentModal] = useState<boolean>(false);
  const ref: any = useRef(null);

  const navigate = useNavigate();

  const getDocument = async () => {
    if (id) {
      const docRef = await firestore().collection("documents").doc(id);
      return docRef.onSnapshot(
        (docSnapshot) => {
          const data: any = docSnapshot.data();
          setDocument(data);
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      );
    }
    return navigate("/documents");
  };

  useEffect(() => {
    getDocument();
  }, []);

  const { activeStep = 1, type } = doc || {};

  useEffect(() => {
    createImage(id);
    ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [activeStep]);

  const updateDocument = async (data: any) => {
    try {
      await firestore()
        .collection("documents")
        .doc(id)
        .set(data, { merge: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      const preview =
        window.innerWidth < 600
          ? previewImages[activeStep - 1]
          : await createImage(id);
      if (mounted) {
        // only try to update if we are subscribed (or mounted)
        updateDocument({ preview });
      }
    })();
  }, [activeStep]);

  useEffect(() => {
    return () => {
      setMounted(false);
    }; // cleanup function
  }, []);

  const saveDocument = async (args: FormValues, changeStep: number) => {
    const data = {
      ...args,
      activeStep: doc.activeStep + (changeStep || 0),
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };
    updateDocument(data);
  };

  const setStep = async (n: number) => {
    const data = {
      activeStep: n,
      updatedAt: firestore.FieldValue.serverTimestamp(),
    };
    updateDocument(data);
  };

  const steps = doc ? documentSteps[doc.type as DocumentTypes] : [];

  const value = useMemo(
    () => ({
      doc,
      steps,
      type,
      activeStep,
      saveDocument,
      setStep,
      setDocument,
      showSendDocumentModal,
    }),
    [doc, activeStep, type, steps]
  );

  return (
    <DocumentContext.Provider value={value}>
      <DocumentBar />
      <Box ref={ref} style={{ scrollMarginTop: 100 }}>
        <Stepper step={activeStep} steps={steps} />
        {doc ? <Forms /> : <Spinner />}
      </Box>
      <ShareDocumentModal
        close={() => showSendDocumentModal(false)}
        show={sendDocumentModal}
      />
    </DocumentContext.Provider>
  );
};

export default DocumentScreen;
