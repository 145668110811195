import React, { FC, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Box, Button, Divider, Text } from "../styles";
import FormInput from "./form/FormInput";
import Modal from "./Modal";
import { functions } from "../firebase/firebaseSetup";
import downloadFile from "./downloadHelper";
import Spinner from "./Spinner";
import AnimatedCheckCirlce from "./AnimatedCheckCirlce";
import validateEmail from "../lib/validateEmail";

interface Props {
  finished?: boolean;
  show: boolean;
  close: () => void;
}

const AbsoluteContainer = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  inset: 0;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

const ShareDocumentModal: FC<Props> = ({ show, close, finished }) => {
  const { id }: any = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const inputRef = useRef<null | HTMLInputElement>(null);

  const generatePDF = async () => {
    setLoading(true);
    try {
      const pdf: any = functions.httpsCallable("generatePDF");
      const result = await pdf({ id });
      downloadFile(result.data.pdf, result.data.filename);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const sendPDF = async () => {
    setLoading(true);
    try {
      if (!inputRef.current) return null;
      const { value: email } = inputRef.current;
      if (!validateEmail(email)) return setEmailError(true);
      const pdf: any = functions.httpsCallable("generatePDF");
      await pdf({ id, email });
      return close();
    } catch (e) {
      return null;

      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal close={close} show={show}>
      <Box p={["0", "2rem"]}>
        <Box mb="4rem">
          {finished ? (
            <>
              <AnimatedCheckCirlce />
              <Text fontSize="2.4rem" fontWeight="600">
                Uw document is klaar!
              </Text>
            </>
          ) : (
            <Text fontSize="2.4rem" fontWeight="600">
              Deel dit document
            </Text>
          )}
        </Box>
        <Box flexDirection="row" alignItems="end">
          <FormInput
            label="Versturen via e-mailadres"
            onChange={() => setEmailError(false)}
            error={emailError ? "Vul een geldig e-mailadres in." : ""}
            ref={inputRef}
          />
          <Button variant="primary" width={130} ml={1} onClick={sendPDF}>
            Verzenden
          </Button>
        </Box>
        <Divider my="4rem" />
        <Button variant="tertiary" width={200} onClick={generatePDF}>
          DOWNLOAD PDF
        </Button>
        {loading ? (
          <AbsoluteContainer>
            <Spinner />
          </AbsoluteContainer>
        ) : null}
      </Box>
    </Modal>
  );
};

export default ShareDocumentModal;
