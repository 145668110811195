import React, { useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useAuth } from "../../context/AuthContext";
import validateEmail from "../../lib/validateEmail";
import { Box, Button, NavLink, Text } from "../../styles";
import FormCheckbox from "./FormCheckbox";
import FormInput from "./FormInput";

type FormValues = {
  password: string;
  email: string;
  remember: boolean;
};

type ErrorValues = {
  password?: object;
  email?: object;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.email) {
    errors.email = { type: "required", message: "Dit veld is verplicht." };
  }
  if (values.email && !validateEmail(values.email)) {
    errors.email = {
      type: "required",
      message: "Vul een geldig e-mailadres in.",
    };
  }
  if (!values.password) {
    errors.password = { type: "required", message: "Dit veld is verplicht." };
  }
  return {
    values,
    errors,
  };
};

const LoginForm = () => {
  const { login } = useAuth();
  const [invalidUser, setInvalidUser] = useState(false);
  const [rememberCheck, setRememberCheck] = useState(
    () => localStorage.getItem("iedema-forms-remember") === "yes"
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      await login(email, password);
    } catch (e) {
      setInvalidUser(true);
    }
  };

  const toggleRemember = () => {
    localStorage.setItem("iedema-forms-remember", rememberCheck ? "no" : "yes");
    setRememberCheck(!rememberCheck);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {invalidUser ? (
        <Box bg="errorLight" p={16} borderRadius={10} mb={2}>
          <Text color="error">
            Geen gebruiker gevonden die overeenkomt met deze gegevens
          </Text>
        </Box>
      ) : null}
      <FormInput
        label="E-mail"
        {...register("email")}
        mb={2}
        error={errors?.email?.message}
      />
      <FormInput
        label="Password"
        type="password"
        {...register("password")}
        error={errors?.password?.message}
        mb={2}
      />
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <FormCheckbox
          label="Onthouden"
          checked={rememberCheck}
          onChange={toggleRemember}
        />
        <NavLink to="/forgot">Wachtwoord vergeten</NavLink>
      </Box>
      <Button variant="primary" type="submit" mb={1} width="100%">
        Log in
      </Button>
    </form>
  );
};

export default LoginForm;
