import React from "react";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as FormsIcon } from "../assets/icon/forms.svg";
import { Box } from "../styles";
import DocumentsScreen from "../screen/DocumentsScreen";
import DocumentScreen from "../screen/DocumentScreen";
import { devices } from "../lib/sizes";

const HomeLink = styled(Link)`
  display: inline-block;
  position: fixed;
  bottom: 4rem;
  left: 4rem;
  z-index: 10;

  @media ${devices.tablet} {
    bottom: 3rem;
    left: 3rem;
  }
  @media ${devices.mobile} {
    display: none;
  }
`;

const AppLayout = () => {
  return (
    <Box
      height="100%"
      width={1260}
      maxWidth="100%"
      maxHeight="100%"
      overflow="auto"
      mx="auto"
      px={[2, 3, 4]}
      id="main"
    >
      <Box flexGrow={1} py={[2, 3, 4]}>
        <Routes>
          <Route path="documents">
            <Route index element={<DocumentsScreen />} />
            <Route path=":id" element={<DocumentScreen />} />
          </Route>
          <Route path="*" element={<Navigate replace to="/documents" />} />
        </Routes>
      </Box>
      <HomeLink to="/">
        <FormsIcon />
      </HomeLink>
    </Box>
  );
};

export default AppLayout;
