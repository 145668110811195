import React, { ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Input, Label } from "../../styles";
import Warning from "../Warning";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  ref?: string;
  error?: string;
  mb?: number;
}

const InputContainer = styled.div`
  position: relative;
`;

const FormInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, mb, error, ...otherProps },
  ref
) => {
  return (
    <Label htmlFor={name} mb={mb}>
      {label}
      <InputContainer>
        <Input
          {...otherProps}
          name={name}
          ref={ref}
          error={error}
          borderColor={error ? "error" : ""}
          backgroundColor={error ? "errorLight" : ""}
        />
        {error ? <Warning title={error} /> : null}
      </InputContainer>
    </Label>
  );
};

export default React.forwardRef(FormInput);
