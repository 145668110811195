import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { position, PositionProps } from "styled-system";
import { Box, Button } from "../styles";

const DropdownContainer = styled.div`
  position: absolute;
  z-index: 10;
  background-color: white;
  top: 100%;
  box-shadow: 0px 0.4rem 2.4rem rgba(16, 24, 47, 0.2);
  border-radius: 0.8rem;
  left: 0;
  padding: 1rem;

  ${position}
`;

interface Options {
  key: string;
  value: string;
}

interface Props extends PositionProps {
  show: boolean;
  options: Array<Options>;
  onChange: (option: string) => void;
  hide: () => void;
}

const Dropdown: FC<Props> = ({ show, options, onChange, hide, ...props }) => {
  const ref = useRef<null | HTMLDivElement>(null);

  const handleClickOutside = (event: Event | null) => {
    if (ref?.current && !ref.current?.contains(event?.target as HTMLDivElement))
      hide();
  };

  useEffect(() => {
    if (show) {
      return document.addEventListener("mouseup", handleClickOutside);
    }
    return document.removeEventListener("mouseup", handleClickOutside);
  }, [show]);

  return show ? (
    <DropdownContainer {...props} ref={ref}>
      {options.map((option: Options) => (
        <Box key={option.key} px={1} flexDirection="row">
          <Button variant="transparent" onClick={() => onChange(option.key)}>
            {option.value}
          </Button>
        </Box>
      ))}
    </DropdownContainer>
  ) : null;
};

export default Dropdown;
