import React from "react";
import { Button } from "../styles";
import { signInWithGoogle } from "../firebase/firebaseSetup";
import { ReactComponent as GoogleIcon } from "../assets/icon/google.svg";

const GoogleSignInButton = () => {
  return (
    <Button variant="secondary" onClick={signInWithGoogle} mb={1}>
      <GoogleIcon />
      Sign in with Google
    </Button>
  );
};

export default GoogleSignInButton;
