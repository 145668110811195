import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../assets/icon/close.svg";
import { devices } from "../lib/sizes";

interface Props {
  children: ReactNode;
  close: () => void;
  show: boolean;
}

const ModalMain = styled.div`
  animation: fadeIn 0.25s ease;
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5000;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ActiveOverlay = styled.button`
  all: unset;
  background-color: ${({ theme }) => theme.colors.main};
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.main};
  opacity: 0.5;
`;

const ModalContainer = styled.div`
  background-color: white;
  padding: 2rem;
  animation: scaleUp 0.1s ease;
  border-radius: 1.4rem;
  max-width: 100%;
  z-index: 1;

  min-width: 350px;
  min-height: 350px;

  @keyframes scaleUp {
    0% {
      transform: scale(0) translateY(-100px);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const CloseButton = styled.button`
  all: unset;
  position: absolute;
  right: 0;
  top: 0;
  width: 4rem;
  height: 4rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media ${devices.mobile} {
    margin-top: 1rem;
  }
`;

const Modal: FC<Props> = ({ children, close, show }) => {
  return show ? (
    <ModalMain>
      <ActiveOverlay onClick={close} />
      <ModalContainer>
        <ModalContent>
          <CloseButton onClick={close}>
            <CloseIcon />
          </CloseButton>
          {children}
        </ModalContent>
      </ModalContainer>
    </ModalMain>
  ) : null;
};

export default Modal;
