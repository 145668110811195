import React, { useMemo } from "react";
import {
  IntakeRoomsForm,
  IntakeWorkspaceForm,
  IntakeGeneralDataForm,
  IntakeStartForm,
  WorkInspectionStartForm,
  WorkInspectionSafetyForm,
  WorkInspectionQualityForm,
  DeliveryStartForm,
  DeliverySafetyForm,
  DeliveryQualityForm,
} from "..";
import { useDocument } from "../../context/DocumentContext";

import { Box } from "../../styles";

const Forms = () => {
  const { activeStep, type } = useDocument();

  const FormByType = useMemo(() => {
    return {
      INTAKE: [
        <IntakeStartForm />,
        <IntakeGeneralDataForm />,
        <IntakeWorkspaceForm />,
        <IntakeRoomsForm />,
      ],
      WORK_INSPECTION: [
        <WorkInspectionStartForm />,
        <WorkInspectionSafetyForm />,
        <WorkInspectionQualityForm />,
      ],
      DELIVERY: [
        <DeliveryStartForm />,
        <DeliverySafetyForm />,
        <DeliveryQualityForm />,
      ],
    }[type];
  }, [type]);

  return <Box pb={8}>{FormByType?.[activeStep - 1]}</Box>;
};

export default Forms;
