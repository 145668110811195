import React, { FC } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icon/arrow-left.svg";
import { devices } from "../../lib/sizes";
import { Box, Button } from "../../styles";

const StickyBox = styled(Box)`
  flex-direction: row;
  margin-top: 4rem;
  width: 100%;
  justify-content: flex-end;
  z-index: 10;
  position: sticky;
  bottom: 0;
  padding: 2rem 0;
  background: linear-gradient(
    180deg,
    rgba(249, 252, 255, 0) 0%,
    #f9fcff 55.73%
  );
  @media ${devices.mobile} {
    justify-content: space-between;
    position: fixed;
    padding: 2rem;
    left: 0;
    z-index: 100;
  }
`;

interface Props {
  onPrevious?: () => void;
  onNext?: () => void;
  onFinish?: () => void;
  onSave?: () => void;
}

const FormButtons: FC<Props> = ({ onPrevious, onFinish, onNext, onSave }) => {
  return (
    <StickyBox>
      <Button
        variant="empty"
        type="submit"
        value={0}
        id="document-submit-btn"
        onClick={onSave}
      />
      {onPrevious ? (
        <Button
          variant="transparent"
          type="submit"
          width={100}
          value={-1}
          onClick={onPrevious}
        >
          <ArrowLeftIcon />
          Vorige
        </Button>
      ) : null}
      <Button
        variant="primary"
        type="submit"
        ml={2}
        width={180}
        value={1}
        onClick={onFinish || onNext}
      >
        {onFinish ? "Voltooien" : "Volgende"}
      </Button>
    </StickyBox>
  );
};

export default FormButtons;
