import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import skirtingBoardOptions from "../../lib/skirtingBoardOptions";
import { RoomTypes } from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Button, Grid } from "../../styles";
import CheckBlock from "../CheckBlock";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const RoomSkirtingBoardsForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { isDirty },
    reset,
    watch,
  } = useForm<RoomTypes>({
    defaultValues: useMemo(() => {
      return {
        destroySkirting: room.destroySkirting,
        skirtingAttached: room.skirtingAttached,
        skirtingBelow: room.skirtingBelow,
        skirtingOther: room.skirtingOther,
        skirtingComment: room.skirtingComment,
        skirtingBoardsImages: room.skirtingBoardsImages,
      };
    }, [room]),
  });

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: any) => ({
    value: getValues(name),
    name,
    control,
  });

  const onSubmit = async (args: RoomTypes) => {
    const skirtingBoardsImages = await updateAndGetFiles(
      room.skirtingBoardsImages,
      args.skirtingBoardsImages
    );
    const onSuccess = await saveRoom({ ...args, skirtingBoardsImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("skirtingBoardsImages") || [],
      control,
      setValue,
      ...register("skirtingBoardsImages"),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Box mb={2}>
          <Grid
            gridTemplateColumns={["1fr", "1fr"]}
            gridColumnGap={1}
            gridRowGap={1}
          >
            {skirtingBoardOptions.map(({ key, value }) => (
              <CheckBlock key={key} label={value} {...values(key)} />
            ))}
          </Grid>
        </Box>
        <Box mb={2}>
          <FormTextarea label="Toelichting" {...register("skirtingComment")} />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-plinten`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default RoomSkirtingBoardsForm;
