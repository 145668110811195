import React, { useEffect, useMemo, useState } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  DocumentType,
  DeliveryQualityErrorValues,
  DeliveryQualityField,
} from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box } from "../../styles";
import ClosedQuestions from "../ClosedQuestions";
import ShareDocumentModal from "../ShareDocumentModal";
import FormButtons from "./FormButtons";
import FormInput from "./FormInput";
import FormUpload from "./FormUpload";

const resolver: Resolver<DocumentType> = async (values) => {
  const errors: DeliveryQualityErrorValues = {};

  if (
    values.deliveryDiscussedWithClient &&
    !values.deliveryDiscussedWithClientComment
  ) {
    errors.deliveryDiscussedWithClientComment = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (values.agreementsWithClient && !values.agreementsWithClientComment) {
    errors.agreementsWithClientComment = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  return {
    values,
    errors,
  };
};

const DeliveryQualityForm = () => {
  const { doc, saveDocument } = useDocument();
  const { id } = useParams();
  const [finishDocumentModal, showFinishDocumentModal] =
    useState<boolean>(false);

  const {
    handleSubmit,
    getValues,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DocumentType>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        roomLeftTidy: doc.roomLeftTidy,
        deliveryDiscussedWithClient: doc.deliveryDiscussedWithClient,
        deliveryDiscussedWithClientComment:
          doc.deliveryDiscussedWithClientComment,
        agreementsWithClient: doc.agreementsWithClient,
        agreementsWithClientComment: doc.agreementsWithClientComment,
        productsProperlyInstalled: doc.productsProperlyInstalled,
        productsProperlyInstalledComment: doc.productsProperlyInstalledComment,
        deliverySpaceImages: doc.deliverySpaceImages,
      };
    }, [doc]),
  });

  const onSubmit = async (
    args: DocumentType,
    changeStep: number,
    finished?: boolean
  ) => {
    const deliverySpaceImages = await updateAndGetFiles(
      doc.deliverySpaceImages,
      args.deliverySpaceImages
    );
    if (id) {
      try {
        await saveDocument({ ...args, deliverySpaceImages }, changeStep);
        if (finished) showFinishDocumentModal(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: DeliveryQualityField) => ({
    value: getValues(name),
    name,
    control,
  });

  const getFormUploadProps = () => {
    return {
      files: getValues("deliverySpaceImages") || [],
      control,
      setValue,
      ...register("deliverySpaceImages"),
    };
  };

  return (
    <form onSubmit={preventDefault}>
      <ClosedQuestions
        title="Is de ruimte netjes achtergelaten? Voeg onderaan een foto van de ruimte toe"
        {...values("roomLeftTidy")}
      />
      <ClosedQuestions
        title="Is de oplevering met de klant besproken?"
        {...values("deliveryDiscussedWithClient")}
      >
        <FormInput
          label="Met wie"
          {...register("deliveryDiscussedWithClientComment")}
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Zijn er afspraken gemaakt met de klant?"
        {...values("agreementsWithClient")}
      >
        <FormInput
          label="welke afspraken zijn gemaakt?"
          error={errors?.agreementsWithClientComment?.message}
          {...register("agreementsWithClientComment")}
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Zijn de producten netjes geinstalleerd en werken ze naar behoren?"
        onFalse
        {...values("productsProperlyInstalled")}
      >
        <FormInput
          label="Toelichting"
          error={errors?.productsProperlyInstalledComment?.message}
          {...register("productsProperlyInstalledComment")}
        />
      </ClosedQuestions>
      <Box mt={4}>
        <FormUpload
          label="Voeg een foto toe die een goed beeld van de de opgeleverde ruimte. "
          {...getFormUploadProps()}
          full
          defaultFilename="opgeleverde-ruimte"
        />
      </Box>
      <FormButtons
        onPrevious={handleSubmit((args: DocumentType) => onSubmit(args, -1))}
        onFinish={handleSubmit((args: DocumentType) => onSubmit(args, 0, true))}
        onSave={handleSubmit((args: DocumentType) => onSubmit(args, 0))}
      />
      <ShareDocumentModal
        show={finishDocumentModal}
        finished
        close={() => showFinishDocumentModal(false)}
      />
    </form>
  );
};

export default DeliveryQualityForm;
