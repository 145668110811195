const workInspectionActivitiesCheckFields = [
  { key: "demolish", value: "Slopen" },
  { key: "equalize", value: "Egaliseren" },
  { key: "layingfloors", value: "Vloeren leggen" },
  { key: "installingSkirtingBoard", value: "Plinten plaatsen" },
  { key: "installingRails", value: "Rails plaatsen" },
  { key: "installingIndoorSunEffect", value: "Binnen zonwering plaatsen" },
];

export default workInspectionActivitiesCheckFields;
