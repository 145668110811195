import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";

const ImgContainer = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 2px solid #2e79f1;
`;

const LetterContainer = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 36px;
  border: 2px solid #2e79f1;
  color: #2e79f1;
  background-color: #e8f1ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
`;

const UserThumbnail = () => {
  const { user } = useAuth();
  const [error, setError] = useState<boolean>(false);

  return user?.photoURL && !error ? (
    <ImgContainer src={user.photoURL} onError={() => setError(true)} />
  ) : (
    <LetterContainer>{user?.email?.[0]?.toUpperCase() || ""}</LetterContainer>
  );
};

export default UserThumbnail;
