import React, { ReactNode, useEffect, useState, useMemo } from "react";
import firebase from "firebase/compat/app";
import { auth } from "../firebase/firebaseSetup";
import { AuthContext } from "../context/AuthContext";
import { Spinner } from "../component";

interface Props {
  children?: ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [appInit, setAppInit] = useState<boolean>(false);

  const logout = async () => {
    await auth.signOut();
  };

  useEffect(() => {
    const remember = localStorage.getItem("iedema-forms-remember") === "yes";
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
      firebase
        .auth()
        .setPersistence(
          remember
            ? firebase.auth.Auth.Persistence.LOCAL
            : firebase.auth.Auth.Persistence.SESSION
        );
      if (!appInit) {
        setTimeout(() => setAppInit(true), 300);
      }
    });
    return unsubscribe;
  }, [appInit]);

  const login = async (email: string, password: string) => {
    await auth.signInWithEmailAndPassword(email, password);
  };

  const value = useMemo(
    () => ({
      user,
      setUser,
      login,
      logout,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={value}>
      {appInit ? children : <Spinner />}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
