import React, { useState } from "react";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as EllipsisIcon } from "../assets/icon/ellipsis.svg";
import { ReactComponent as Logo } from "../assets/icon/logo.svg";
import Hero from "./Hero";

import { Block, Box, Button } from "../styles";
import documentIcons from "../lib/documentIcons";
import AbsoluteShadedBox from "../styles/AbsoluteFadedBox";
import Dropdown from "./Dropdown";
import { DocumentType } from "../lib/types";
import UpdateDocumentModal from "./UpdateDocumentModal";
import { firestore } from "../firebase/firebaseSetup";

interface Props {
  document: DocumentType;
}

interface DocumentInfo {
  [key: string]: string;
}

const documentNames: DocumentInfo = {
  INTAKE: "Opname",
  WORK_INSPECTION: "Inspectie",
  DELIVERY: "Oplevering",
};

const DocumentBlock = styled(Block)`
  flex-grow: 1;
  max-width: 500px;
  height: 240px;
  flex-direction: column;
  background-color: white;
  padding: 0;
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.primaryLight};
  }
`;
const DocumentLink = styled(Link)`
  text-decoration: none;
  flex-grow: 1;
  min-width: 30%;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const options = [
  { key: "DUPLICATE", value: "Document dupliceren" },
  { key: "EDIT", value: "Naam wijzigen" },
  { key: "DELETE", value: "Document verwijderen" },
];

const Document = ({ document }: Props) => {
  const [showActions, setShowActions] = useState<boolean>(false);
  const [imgError, setImgError] = useState<boolean>(false);
  const [showUpdateDocumentModal, setShowUpdateDocumentModal] =
    useState<boolean>(false);

  const duplicateDocument = async () => {
    try {
      const { id } = await firestore()
        .collection("documents")
        .add({
          ...document,
          roomTemperatureImages: undefined,
          floorTemperatureImages: undefined,
          floorMoistureImages: undefined,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp(),
        });

      const rooms = await firestore()
        .collection(`documents/${document.id}/rooms`)
        .get();
      const batch = firestore().batch();
      rooms.docs.forEach((roomDoc) => {
        batch.set(firestore().collection(`documents/${id}/rooms`).doc(), {
          ...roomDoc.data(),
          undergroundImages: undefined,
          undergroundPreparationImages: undefined,
          applyCoverImages: undefined,
          curtainImages: undefined,
          coveringsImages: undefined,
          skirtingBoardsImages: undefined,
          doorwaysImages: undefined,
          otherImages: undefined,
        });
      });
      await batch.commit();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const deleteDocument = async () => {
    try {
      await firestore().collection("documents").doc(document.id).delete();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleChange = (key: string) => {
    setShowActions(false);
    if (key === "DUPLICATE") return duplicateDocument();
    if (key === "DELETE") return deleteDocument();
    return setShowUpdateDocumentModal(true);
  };

  return (
    <Box>
      <DocumentLink to={`/documents/${document.id}`} key={document.id}>
        <DocumentBlock>
          <Box
            flexGrow={1}
            width="100%"
            borderBottom="1px solid #eceeef"
            justifyContent="center"
            alignItems="center"
            borderTopLeftRadius={14}
            borderTopRightRadius={14}
            overflow="hidden"
            backgroundColor="background"
            p={1}
          >
            {document.preview && !imgError ? (
              <Image
                src={document.preview}
                alt={`document ${document.id}`}
                onError={() => setImgError(true)}
              />
            ) : (
              <Logo />
            )}
          </Box>
          <Box
            height={60}
            minHeight={60}
            width="100%"
            borderRadius={14}
            flexDirection="row"
            alignItems="center"
            pl="2rem"
            pr="1rem"
          >
            {documentIcons[document.type]}
            <Box ml="1rem" mr="auto">
              <Hero
                title={document.name || documentNames[document.type]}
                fontSizeTitle="1.4rem"
                description={`Aangepast op ${
                  document.updatedAt?.toDate?.().toISOString().split("T")[0] ||
                  ""
                }`}
                fontSizeDescription="1.2rem"
              />
            </Box>
          </Box>
        </DocumentBlock>
      </DocumentLink>
      <AbsoluteShadedBox bottom="1rem" top="auto">
        <Button variant="icon" onClick={() => setShowActions((prev) => !prev)}>
          <EllipsisIcon />
        </Button>
        <Dropdown
          show={showActions}
          onChange={handleChange}
          options={options}
          right="0px"
          hide={() => setShowActions(false)}
          left="auto"
        />
      </AbsoluteShadedBox>
      <UpdateDocumentModal
        show={showUpdateDocumentModal}
        close={() => setShowUpdateDocumentModal(false)}
        doc={document}
      />
    </Box>
  );
};

export default Document;
