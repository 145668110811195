import styled from "styled-components";
import {
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  space,
  SpaceProps,
} from "styled-system";

interface Props extends SpaceProps, FontSizeProps, FontWeightProps {
  space?: string;
  fontSize?: string;
  error?: string;
}
const Label = styled.label<Props>`
  font-size: 1.6rem;
  display: block;

  input,
  select,
  textarea,
  button {
    margin-top: 5px;
  }

  ${fontSize}
  ${space}
  ${fontWeight}

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
  }
`;

export default Label;
