import React, { useMemo } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { useDocument } from "../../context/DocumentContext";
import kindOptions from "../../lib/kindOptions";
import { Grid } from "../../styles";
import FormButtons from "./FormButtons";
import FormDate from "./FormDate";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import preventDefault from "../../lib/preventDefault";
import { ErrorValues } from "../../lib/types";

type FormValues = {
  createdByName: string;
  projectNumber: string;
  recordingDate: Date;
  kind?: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.createdByName) {
    errors.createdByName = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.projectNumber) {
    errors.projectNumber = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.recordingDate) {
    errors.recordingDate = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.kind) {
    errors.kind = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  return {
    values,
    errors,
  };
};

const IntakeStartForm = () => {
  const { doc }: { doc: any } = useDocument();
  const { saveDocument } = useDocument();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        createdByName: doc.createdByName,
        projectNumber: doc.projectNumber,
        recordingDate: doc.recordingDate?.toDate?.(),
        kind: doc.kind,
      };
    }, [doc]),
  });
  const watchKind = watch("kind");

  return (
    <form onSubmit={preventDefault}>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
        <FormInput
          label="Opgenomen door"
          error={errors?.createdByName?.message}
          {...register("createdByName")}
        />
        <FormInput
          label="Projectnummer"
          error={errors?.projectNumber?.message}
          {...register("projectNumber")}
        />
        <Controller
          control={control}
          name="recordingDate"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              error={errors?.recordingDate?.message}
              label="Datum opname"
            />
          )}
        />
        <FormSelect
          label="Soort opname"
          options={kindOptions}
          error={errors?.kind?.message}
          selected={!!watchKind}
          {...register("kind")}
        />
      </Grid>
      <FormButtons
        onNext={handleSubmit((args) => saveDocument(args, 1))}
        onSave={handleSubmit((args) => saveDocument(args, 0))}
      />
    </form>
  );
};

export default IntakeStartForm;
