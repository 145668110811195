import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import { RoomOtherType } from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Button } from "../../styles";
import FormInput from "./FormInput";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const RoomOtherForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<RoomOtherType>({
    defaultValues: useMemo(() => {
      return {
        roomOther: room.roomOther,
        roomOtherComment: room.roomOtherComment,
        otherImages: room.otherImages,
      };
    }, [room]),
  });

  const onSubmit = async (args: RoomOtherType) => {
    const otherImages = await updateAndGetFiles(
      room.otherImages,
      args.otherImages
    );
    const onSuccess = await saveRoom({ ...args, otherImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("otherImages") || [],
      control,
      setValue,
      ...register("otherImages"),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Box mb={2}>
          <FormInput label="Overige namelijk:" {...register("roomOther")} />
        </Box>
        <Box mb={2}>
          <FormTextarea label="Toelichting" {...register("roomOtherComment")} />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-overige`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default RoomOtherForm;
