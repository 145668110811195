import React, { ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import styled from "styled-components";
import theme from "../../lib/theme";
import { Label } from "../../styles";
import Select from "../../styles/Select";
import Warning from "../Warning";

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  ref?: string;
  error?: string;
  selected: boolean;
  mb?: number;
  options: Array<{ key: string; value: string }>;
}

const SelectContainer = styled.div`
  position: relative;
`;

const FormInput: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  { name, label, mb, options, selected, error, ...otherProps },
  ref
) => {
  return (
    <Label htmlFor={name} mb={mb}>
      {label}
      <SelectContainer>
        <Select
          {...otherProps}
          name={name}
          ref={ref}
          error={error}
          style={{
            color: selected ? theme.colors.main : theme.colors.disabled,
          }}
        >
          <option disabled selected hidden value="">
            Maak uw keuze
          </option>
          {options.map(({ key, value }) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </Select>
        {error ? <Warning title={error} /> : null}
      </SelectContainer>
    </Label>
  );
};

export default React.forwardRef(FormInput);
