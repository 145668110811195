interface DocumentStepTypes {
  INTAKE: string[];
  WORK_INSPECTION: string[];
  DELIVERY: string[];
}
const documentSteps: DocumentStepTypes = {
  INTAKE: ["Start", "Algemene gegevens", "Werkplek", "Ruimtes"],
  WORK_INSPECTION: ["Start", "Veiligheid", "Kwaliteit"],
  DELIVERY: ["Start", "Veiligheid", "Kwaliteit"],
};

export default documentSteps;
