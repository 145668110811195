import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, NavLink } from "../../styles";
import FormInput from "./FormInput";
import { auth } from "../../firebase/firebaseSetup";

type FormValues = {
  password: string;
  email: string;
};

interface Props {
  onSuccess: () => void;
}

const ForgotPasswordForm: FC<Props> = ({ onSuccess }) => {
  const { register, handleSubmit } = useForm<FormValues>();
  const [error, setError] = useState("");

  const onSubmit = async ({ email }: FormValues) => {
    try {
      await auth.sendPasswordResetEmail(email);
      onSuccess();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setError("Er is geen account gevonden voor dit e-mail adres");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInput label="E-mail" {...register("email")} mb={2} error={error} />
      <Button variant="primary" type="submit" mb={1} width="100%">
        Versturen
      </Button>
      <NavLink to="/">Terug naar login</NavLink>
    </form>
  );
};

export default ForgotPasswordForm;
