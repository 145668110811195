import { sizes } from "./sizes";

const theme = {
  colors: {
    main: "#10182F",
    primary: "#2E79F1",
    primaryLight: "#E8F1FF",
    primaryDark: "#2E4DF1",
    secondary: "#ECEEEF",
    white: "#fff",
    disabled: "#B3BAD0",
    error: "#EB575E",
    errorLight: "#FFE9E9",
    background: "#F9FCFF",
  },
  space: Array(10)
    .fill(0)
    .map((_, i) => `${i}rem`),
  fontSizes: ["1rem", "1.2rem", "1.4rem", "1.6rem", "1.8rem", "2.4rem"],
  breakpoints: [sizes.mobile, sizes.tablet, sizes.laptop, sizes.desktop],
};

export default theme;
