import React, { useEffect, useMemo } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  DocumentType,
  DeliverySafetyField,
  DeliverySafetyErrorValues,
} from "../../lib/types";
import { Grid } from "../../styles";
import ClosedQuestions from "../ClosedQuestions";
import FormButtons from "./FormButtons";
import FormDate from "./FormDate";
import FormInput from "./FormInput";

const resolver: Resolver<DocumentType> = async (values) => {
  const errors: DeliverySafetyErrorValues = {};

  if (values.unsafeSituations && !values.unsafeSituationsComment) {
    errors.unsafeSituationsComment = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (values.accidents && !values.accidentsComment) {
    errors.unsafeSituationsComment = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  return {
    values,
    errors,
  };
};

const DeliverySafetyForm = () => {
  const { doc, saveDocument } = useDocument();
  const { id } = useParams();

  const {
    handleSubmit,
    getValues,
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<DocumentType>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        unsafeSituations: doc.unsafeSituations,
        unsafeSituationsComment: doc.unsafeSituationsComment,
        accidents: doc.accidents,
        accidentsComment: doc.accidentsComment,
        unsafeSituationReported: doc.unsafeSituationReported,
        unsafeSituationReportedTo: doc.unsafeSituationReportedTo,
        unsafeSituationReportedAt: doc.unsafeSituationReportedAt?.toDate?.(),
      };
    }, [doc]),
  });

  const onSubmit = async (args: DocumentType, changeStep: number) => {
    if (id) {
      try {
        await saveDocument(args, changeStep);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: DeliverySafetyField) => ({
    value: getValues(name),
    name,
    control,
  });

  return (
    <form onSubmit={preventDefault}>
      <ClosedQuestions
        title="Zijn er onveilige situaties geweest tijdens het project?"
        {...values("unsafeSituations")}
      >
        <FormInput
          label="Toelichting"
          error={errors?.unsafeSituationsComment?.message}
          {...register("unsafeSituationsComment")}
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Zijn er ongevallen gebeurd tijdens het project?"
        {...values("accidents")}
      >
        <FormInput
          error={errors?.accidentsComment?.message}
          label="Toelichting"
          {...register("accidentsComment")}
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Onveilige situatie of ongeval gemeld?"
        {...values("unsafeSituationReported")}
      >
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2} mb={2}>
          <FormInput label="Bij" {...register("unsafeSituationReportedTo")} />
          <Controller
            control={control}
            name="unsafeSituationReportedAt"
            render={({ field }) => (
              <FormDate
                onChange={(date) => field.onChange(date)}
                selected={field.value as any}
                label="Op datum"
              />
            )}
          />
        </Grid>
      </ClosedQuestions>
      <FormButtons
        onPrevious={handleSubmit((args: DocumentType) => onSubmit(args, -1))}
        onNext={handleSubmit((args: DocumentType) => onSubmit(args, 1))}
        onSave={handleSubmit((args: DocumentType) => onSubmit(args, 0))}
      />
    </form>
  );
};

export default DeliverySafetyForm;
