import React, { useState } from "react";
import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { Block, Box, Button, DivideContainer, NavLink } from "../styles";
import { ReactComponent as SendIcon } from "../assets/icon/send.svg";
import { ReactComponent as EllipsisIcon } from "../assets/icon/ellipsis.svg";
import { devices } from "../lib/sizes";
import Step from "./Step";
import { useDocument } from "../context/DocumentContext";
import { ReactComponent as SaveIcon } from "../assets/icon/save.svg";
import AnimatedCheck from "./AnimatedCheck";
import Dropdown from "./Dropdown";
import AbsoluteShadedBox from "../styles/AbsoluteFadedBox";

const DocumentsLink = styled(NavLink)`
  white-space: nowrap;
  &:before {
    display: inline-block;
    content: " ";
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.05936 0.315641C6.23021 0.486495 6.23021 0.763505 6.05936 0.934359L1.99372 5L6.05936 9.06564C6.23021 9.2365 6.23021 9.5135 6.05936 9.68436C5.8885 9.85521 5.6115 9.85521 5.44064 9.68436L1.06564 5.30936C0.894786 5.1385 0.894786 4.8615 1.06564 4.69064L5.44064 0.315641C5.6115 0.144786 5.8885 0.144786 6.05936 0.315641Z' fill='%232E79F1'/%3E%3C/svg%3E%0A");
    background-size: 1rem 1rem;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5em;
  }

  @media ${devices.mobile} {
    font-size: 0;
  }
`;

const StickyBox = styled(Box)`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;

  @media ${devices.mobile} {
    width: calc(100% + 4rem);
    margin: -2rem 0 0 -2rem;
  }
`;

type ProcessProps = LayoutProps;

const ProcessBar = styled.div<ProcessProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  background-color: #2e4df1;
  transition: width 1s ease-in-out;

  ${layout}
`;

const options = [
  { key: "SAVE", value: "Opslaan" },
  { key: "SEND", value: "Versturen" },
];

const DocumentBar = () => {
  const { activeStep, setStep, showSendDocumentModal, steps } = useDocument();
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const [showActions, setShowActions] = useState<boolean>(false);

  const save = () => {
    const el: HTMLElement | null = document.getElementById(
      "document-submit-btn"
    );
    if (el) {
      el.click();
      setShowAnimation(true);
    }
  };

  const handleChange = (key: string) => {
    setShowActions(false);
    if (key === "SAVE") return save();
    return showSendDocumentModal(true);
  };

  return (
    <StickyBox>
      <Block
        alignItems="center"
        flexDirection="row"
        pr={[0, 2]}
        borderRadius={[0, 14]}
      >
        <ProcessBar width={`${activeStep * 25}%`} />
        <DivideContainer>
          <DocumentsLink to="/documents">Terug</DocumentsLink>
        </DivideContainer>
        <Box
          flexDirection="row"
          alignItems="center"
          maxWidth="calc(100% - 5rem)"
          overflow="auto"
          height="100%"
          pr="6rem"
        >
          <DivideContainer>
            <Step
              step={1}
              activeStep={activeStep}
              title={steps[0]}
              onClick={setStep}
            />
          </DivideContainer>
          <DivideContainer>
            <Step
              step={2}
              activeStep={activeStep}
              title={steps[1]}
              onClick={setStep}
            />
          </DivideContainer>
          <DivideContainer>
            <Step
              step={3}
              activeStep={activeStep}
              title={steps[2]}
              onClick={setStep}
            />
          </DivideContainer>
          {steps.length > 3 && (
            <Box ml="2rem" mr="4rem">
              <Step
                step={4}
                activeStep={activeStep}
                title={steps[3]}
                onClick={setStep}
              />
            </Box>
          )}
        </Box>
        <Box
          ml="auto"
          flexDirection="row"
          alignItems="center"
          display={["none", "none", "flex"]}
        >
          <DivideContainer mr="1.2rem" pr="1.2rem">
            <Button variant="icon" onClick={save}>
              <Box position="relative">
                <SaveIcon />
                {showAnimation ? (
                  <AnimatedCheck hide={() => setShowAnimation(false)} />
                ) : null}
              </Box>
            </Button>
          </DivideContainer>
          <Button variant="icon" onClick={() => showSendDocumentModal(true)}>
            <SendIcon />
          </Button>
        </Box>
        <AbsoluteShadedBox
          display={["block", "block", "none"]}
          top="1rem"
          right="0"
        >
          <Button
            variant="icon"
            onClick={() => setShowActions((prev) => !prev)}
          >
            <EllipsisIcon />
          </Button>
          <Dropdown
            show={showActions}
            onChange={handleChange}
            options={options}
            right="0px"
            hide={() => setShowActions(false)}
            left="auto"
          />
        </AbsoluteShadedBox>
      </Block>
    </StickyBox>
  );
};

export default DocumentBar;
