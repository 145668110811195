import React, { ReactNode } from "react";
import { ReactComponent as IntakeIcon } from "../assets/icon/intake.svg";
import { ReactComponent as WorkinspectionIcon } from "../assets/icon/workinspection.svg";
import { ReactComponent as DeliveryIcon } from "../assets/icon/delivery.svg";

interface Icons {
  [key: string]: ReactNode;
}

const documentIcons: Icons = {
  INTAKE: <IntakeIcon />,
  WORK_INSPECTION: <WorkinspectionIcon />,
  DELIVERY: <DeliveryIcon />,
};

export default documentIcons;
