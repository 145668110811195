import React, { FC } from "react";
import { Controller, useForm, Resolver } from "react-hook-form";
import { useParams } from "react-router-dom";
import roomKindOptions from "../lib/roomKindOptions";
import { RoomKindKey, RoomKindOptionField, RoomTypes } from "../lib/types";
import { Block, Box, Button, Text } from "../styles";
import FormCheckbox from "./form/FormCheckbox";
import FormInput from "./form/FormInput";
import Modal from "./Modal";
import { firestore } from "../firebase/firebaseSetup";

interface Props {
  show: boolean;
  close: () => void;
  onSuccess: () => void;
}

type ErrorValues = {
  name?: object;
};

const resolver: Resolver<RoomTypes> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.name) {
    errors.name = { type: "required", message: "Dit veld is verplicht." };
  }
  return {
    values,
    errors,
  };
};

const CreateRoomModal: FC<Props> = ({ show, close, onSuccess }) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<RoomTypes>({
    resolver,
  });
  const { id } = useParams();

  const watchRoom = watch();

  const createRoom = async (args: RoomTypes) => {
    await firestore()
      .collection("documents")
      .doc(id)
      .collection("rooms")
      .add({
        ...args,
        createdAt: firestore.FieldValue.serverTimestamp(),
        updatedAt: firestore.FieldValue.serverTimestamp(),
      });
    reset();
    onSuccess();
  };

  const handleClose = () => {
    reset();
    close();
  };

  return (
    <Modal close={handleClose} show={show}>
      <form onSubmit={handleSubmit(createRoom)}>
        <Box p={["1rem 0", "4rem"]} height={["100vh", "auto"]}>
          <Text fontSize="2.4rem" fontWeight="600">
            Ruimte toevoegen
          </Text>
          <Box my={4}>
            <FormInput
              label="Ruimtenaam"
              {...register("name")}
              error={errors?.name?.message}
            />
          </Box>
          <Box mb={2}>
            <Text fontSize="2.4rem" fontWeight="600">
              Uit te voeren werkzaamheden
            </Text>
            <Text fontSize="1.6rem" color="error">
              Elke selectie die u nu maakt heeft gevolgen op de volgende stappen
            </Text>
          </Box>
          <Box mb={4}>
            {roomKindOptions.map(({ kind, options }) => (
              <Box
                key={kind.key}
                mb={1}
                height={[
                  watchRoom[kind.key as RoomKindKey] ? "10rem" : "5rem",
                  "7rem",
                ]}
              >
                <Block
                  px={[1, 2]}
                  py={[1, 0]}
                  display="flex"
                  justifyContent="center"
                  minHeight={[
                    watchRoom[kind.key as RoomKindKey] ? "10rem" : "5rem",
                    "7rem",
                  ]}
                  bg={watchRoom[kind.key as RoomKindKey] ? "primaryLight" : ""}
                  borderColor={
                    watchRoom[kind.key as RoomKindKey] ? "primary" : ""
                  }
                >
                  <Box
                    flexDirection={[
                      watchRoom[kind.key as RoomKindKey] ? "column" : "row",
                      "row",
                    ]}
                    justifyContent={["space-between", "flex-start"]}
                    alignItems={[
                      watchRoom[kind.key as RoomKindKey] ? "start" : "center",
                      "center",
                    ]}
                  >
                    <Box
                      width={160}
                      borderRight={["none", "1px solid #ECEEEF"]}
                      mr={4}
                      alignItems="start"
                    >
                      <Controller
                        name={kind.key as RoomKindKey}
                        control={control}
                        render={(props) => {
                          return (
                            <FormCheckbox
                              label={kind.value}
                              {...props.field}
                              style={{ fontSize: 14, fontWeight: "600" }}
                            />
                          );
                        }}
                      />
                    </Box>
                    <Box
                      flexDirection="row"
                      display={[
                        watchRoom[kind.key as RoomKindKey] ? "flex" : "none",
                        "flex",
                      ]}
                      flexWrap="wrap"
                      opacity={watchRoom[kind.key as RoomKindKey] ? 1 : 0.2}
                      style={{
                        pointerEvents: watchRoom[kind.key as RoomKindKey]
                          ? undefined
                          : "none",
                      }}
                    >
                      {options.map(({ key, value }) => (
                        <Box
                          key={key}
                          mr={2}
                          width={["40%", "min-content"]}
                          mt={[1, 0]}
                        >
                          <Controller
                            name={key as RoomKindOptionField}
                            control={control}
                            render={(props) => {
                              return (
                                <FormCheckbox
                                  label={value}
                                  {...props.field}
                                  style={{ fontSize: 14 }}
                                />
                              );
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Block>
              </Box>
            ))}
          </Box>
          <Button variant="primary" width={["100%", 180]} ml="auto" mt="auto">
            Ruimte toevoegen
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default CreateRoomModal;
