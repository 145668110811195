import removeFile from "./removeFile";
import { ExtendedFileType } from "./types";
import uploadFile from "./uploadFile";

const updateAndGetFiles = async (
  existingFiles: Array<ExtendedFileType>,
  newFiles: Array<ExtendedFileType>
) => {
  // remove existing files from storage if excluded
  if (existingFiles?.length) {
    await Promise.all(
      existingFiles
        .filter(
          (existingFile: ExtendedFileType) =>
            !newFiles.some(
              (argFile: ExtendedFileType) => argFile.url === existingFile.url
            )
        )
        .map((file: ExtendedFileType) => file.url && removeFile(file.url))
    );
  }

  // return files and upload if file is new
  return Promise.all(
    [...newFiles].map(async (newFile: ExtendedFileType) => {
      if (newFile.file) {
        const { file, ...rest } = newFile;
        return {
          ...rest,
          url: await uploadFile(file),
        };
      }
      return newFile;
    })
  );
};

export default updateAndGetFiles;
