import styled from "styled-components";
import {
  color,
  ColorProps,
  border,
  BorderProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
} from "styled-system";

interface Props extends SpaceProps, BorderProps, ColorProps, LayoutProps {
  space?: string;
  border?: string;
  color?: string;
  onChange?: (e: any) => void;
}

const Textarea = styled.textarea<Props>`
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.secondary};
  background-color: "#fff";
  height: 9rem;
  border-radius: 1rem;
  width: 100%;
  padding: 1.6rem;
  font-size: 1.6rem;

  &::placeholder {
    color: ${({ theme }) => theme.colors.disabled};
  }

  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
    background-color: "#e8f1ff";
  }

  ${space}
  ${border}
  ${color}
  ${layout}
`;

export default Textarea;
