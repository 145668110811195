import React, { ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import { Box, CenteredLabel, Checkbox } from "../../styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  value?: any;
  onChange?: (e: any) => void;
}

const FormRadio: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { onChange, value, name }: any,
  ref
) => {
  const defaultFalse = value === false ? "Nee" : undefined;
  const [checkboxState, setCheckboxState] = React.useState(
    value ? "Ja" : defaultFalse
  );

  return (
    <Box flexDirection="row">
      {[
        { name: "Ja", value: true },
        { name: "Nee", value: false },
      ].map((option) => (
        <Box ml={[1, 2]} key={name + option.name}>
          <CenteredLabel>
            <Checkbox
              ref={ref}
              onChange={(e: any) => {
                const { value: checked } = e.target;
                setCheckboxState(
                  checked === checkboxState ? undefined : checked
                );
                if (onChange)
                  onChange(
                    checked === checkboxState ? undefined : checked === "Ja"
                  );
              }}
              type="checkbox"
              name={name}
              value={option.name}
              checked={option.name === checkboxState}
            />
            {option.name}
          </CenteredLabel>
        </Box>
      ))}
    </Box>
  );
};

export default React.forwardRef(FormRadio);
