import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import { DocumentType } from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Button } from "../../styles";
import ClosedQuestions from "../ClosedQuestions";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const RoomUndergroundPreparationForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    formState: { isDirty },
    reset,
  } = useForm<DocumentType>({
    defaultValues: useMemo(() => {
      return {
        demolishDisposeExistingCovering: room.demolishDisposeExistingCovering,
        machineSandingAndVacuuming: room.machineSandingAndVacuuming,
        primingAndLevelingSubstrate: room.primingAndLevelingSubstrate,
        healingRepairingSurface: room.healingRepairingSurface,
        moistureInhibitingMeasures: room.moistureInhibitingMeasures,
        undergroundPreparationComment: room.undergroundPreparationComment,
        undergroundPreparationImages: room.undergroundPreparationImages,
      };
    }, [room]),
  });

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: any) => ({
    value: getValues(name),
    name,
    control,
  });

  const onSubmit = async (args: DocumentType) => {
    const undergroundPreparationImages = await updateAndGetFiles(
      room.undergroundPreparationImages,
      args.undergroundPreparationImages
    );
    const onSuccess = await saveRoom({ ...args, undergroundPreparationImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("undergroundPreparationImages") || [],
      control,
      setValue,
      ...register("undergroundPreparationImages"),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Box mb={2}>
          <ClosedQuestions
            title="Slopen en afvoeren betaande bedekking"
            {...values("demolishDisposeExistingCovering")}
          />

          <ClosedQuestions
            title="Machinaal schuren en grondig stofzuigen"
            {...values("machineSandingAndVacuuming")}
          />
          <ClosedQuestions
            title="Primeren en egaliseren ondergrond"
            {...values("primingAndLevelingSubstrate")}
          />
          <ClosedQuestions
            title="Aanhelen/repareren ondergrond"
            {...values("healingRepairingSurface")}
          />
          <ClosedQuestions
            title="Vocht remmende maatregelen"
            {...values("moistureInhibitingMeasures")}
          />
        </Box>
        <Box mb={2}>
          <FormTextarea
            label="Toelichting"
            {...register("undergroundPreparationComment")}
          />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-pre-ondergrond`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default RoomUndergroundPreparationForm;
