import React from "react";
import { GoogleSignInButton, Hero, LoginForm } from "../component";
import MicrosoftSignInButton from "../component/MicrosoftSignInButton";
import { Box, NavLink, Text } from "../styles";

const LoginScreen = () => {
  return (
    <Box>
      <Box m="0 0 5rem 0">
        <Hero
          title="Log in"
          fontSizeTitle="3.2rem"
          description="Welkom terug! Vul hieronder uw gegevens in."
        />
      </Box>
      <LoginForm />
      <GoogleSignInButton />
      <MicrosoftSignInButton />
      <Box flexDirection="row" justifyContent="center">
        <Text fontSize="1.4rem" color="disabled" mr={1}>
          Nog geen account?
        </Text>
        <NavLink to="/signup">Aanmelden</NavLink>
      </Box>
    </Box>
  );
};

export default LoginScreen;
