import React, { FC } from "react";
import { useForm, Resolver } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "../../styles";
import FormInput from "./FormInput";
import { firestore } from "../../firebase/firebaseSetup";
import { useAuth } from "../../context/AuthContext";

type FormValues = {
  name: string;
};

type ErrorValues = {
  name?: object;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.name) {
    errors.name = { type: "required", message: "Dit veld is verplicht." };
  } else if (values.name.length < 3) {
    errors.name = {
      type: "required",
      message: "Vul minstens 3 tekens in.",
    };
  }
  return {
    values,
    errors,
  };
};

interface Props {
  type: string;
  close: () => void;
}

const CreateDocumentForm: FC<Props> = ({ type, close }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const onSubmit = async ({ name }: FormValues) => {
    try {
      if (user) {
        const { uid } = user;
        const { id } = await firestore().collection("documents").add({
          type,
          name,
          nameInsensitive: name.toLowerCase(),
          createdBy: uid,
          activeStep: 1,
          createdAt: firestore.FieldValue.serverTimestamp(),
          updatedAt: firestore.FieldValue.serverTimestamp(),
        });
        navigate(`/documents/${id}`);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box my={4}>
        <FormInput
          label="Formuliernaam"
          {...register("name")}
          mb={2}
          error={errors?.name?.message}
        />
      </Box>
      <Box flexDirection="row" justifyContent="flex-end">
        <Button
          variant="secondary"
          type="submit"
          ml={1}
          width={140}
          onClick={close}
        >
          Annuleren
        </Button>
        <Button variant="primary" type="submit" ml={1} width={140}>
          Aanmaken
        </Button>
      </Box>
    </form>
  );
};

export default CreateDocumentForm;
