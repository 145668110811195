import React, { useMemo } from "react";
import html2canvas from "html2canvas";
import { useAuth } from "./context/AuthContext";
import AppLayout from "./layout/AppLayout";
import AuthLayout from "./layout/AuthLayout";
import { Box } from "./styles";
import { AppContext } from "./context/AppContext";
import { firebase } from "./firebase/firebaseSetup";
import theme from "./lib/theme";

const App = () => {
  const { user } = useAuth();
  const previewRef =
    React.useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const createImage = async (docId: string) => {
    const element = previewRef.current;
    if (element) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          html2canvas(element, {
            windowWidth: 1440,
            scale: 1,
            scrollY: 0,
            backgroundColor: theme.colors.primaryLight,
          }).then((canvas) => {
            canvas.toBlob(async (blob: Blob | null) => {
              try {
                if (blob) {
                  const image = await firebase.storage().ref(docId).put(blob);
                  const preview = await image.ref.getDownloadURL();
                  resolve(preview);
                }
              } catch (e) {
                reject(e);
                // eslint-disable-next-line no-console
                console.error(e);
              }
            });
          });
        }, 500);
      });
    }
    return null;
  };

  const value = useMemo(
    () => ({
      createImage,
    }),
    [createImage]
  );

  return (
    <AppContext.Provider value={value}>
      <Box
        height="100%"
        maxWidth="100%"
        overflow="hidden"
        ref={previewRef}
        bg="background"
      >
        {user ? <AppLayout /> : <AuthLayout />}
      </Box>
    </AppContext.Provider>
  );
};

export default App;
