import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  DocumentImageField,
  DocumentType,
  QualityField,
} from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import wasteDisposalOptions from "../../lib/wasteDisposalOptions";
import { Box, Divider, Grid, Text } from "../../styles";
import ClosedQuestions from "../ClosedQuestions";
import ShareDocumentModal from "../ShareDocumentModal";
import FormButtons from "./FormButtons";
import FormCheckbox from "./FormCheckbox";
import FormDate from "./FormDate";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormUpload from "./FormUpload";

const IntakeWorkspaceForm = () => {
  const { doc, saveDocument } = useDocument();
  const { id } = useParams();
  const [finishDocumentModal, showFinishDocumentModal] =
    useState<boolean>(false);

  const { handleSubmit, getValues, register, setValue, control, watch } =
    useForm<DocumentType>({
      defaultValues: useMemo(() => {
        return {
          electricityAvailable: doc.electricityAvailable,
          waterAvailable: doc.waterAvailable,
          transportParticularities: doc.transportParticularities,
          roadPlatesNecessary: doc.roadPlatesNecessary,
          forkliftNecessary: doc.forkliftNecessary,
          customerElevatorMessage: doc.customerElevatorMessage,
          ownElevatorMessage: doc.ownElevatorMessage,
          onGroundFloor: doc.onGroundFloor,
          storageAvailable: doc.storageAvailable,
          storageComment: doc.storageComment,
          thirdPartiesWorking: doc.thirdPartiesWorking,
          thirdPartiesWorkingComment: doc.thirdPartiesWorkingComment,
          thirdPartiesTuning: doc.thirdPartiesTuning,
          thirdPartiesTuningComment: doc.thirdPartiesTuningComment,
          wasteDisposal: doc.wasteDisposal,
          wasteDisposalOther: doc.wasteDisposalOther,
          transportParticularitiesComment: doc.transportParticularitiesComment,
          roomTemperatureMeasuredAt: doc.roomTemperatureMeasuredAt?.toDate?.(),
          roomTemperatureValue: doc.roomTemperatureValue,
          roomTemperatureImages: doc.roomTemperatureImages,
          floorTemperatureMeasuredAt:
            doc.floorTemperatureMeasuredAt?.toDate?.(),
          floorTemperatureValue: doc.floorTemperatureValue,
          floorTemperatureImages: doc.floorTemperatureImages,
          floorMoistureMeasuredAt: doc.floorMoistureMeasuredAt?.toDate?.(),
          floorMoistureValue: doc.floorMoistureValue,
          floorMoistureImages: doc.floorMoistureImages,
          floorCantileveredVentilated: doc.floorCantileveredVentilated,
          floorNoLooseParts: doc.floorNoLooseParts,
          layingPlanAvailable: doc.layingPlanAvailable,
          materialsAcclimated: doc.materialsAcclimated,
        };
      }, [doc]),
    });

  const onSubmit = async (
    args: DocumentType,
    changeStep: number,
    finished?: boolean
  ) => {
    if (id) {
      const roomTemperatureImages = await updateAndGetFiles(
        doc.roomTemperatureImages,
        args.roomTemperatureImages
      );
      const floorTemperatureImages = await updateAndGetFiles(
        doc.floorTemperatureImages,
        args.floorTemperatureImages
      );
      const floorMoistureImages = await updateAndGetFiles(
        doc.floorMoistureImages,
        args.floorMoistureImages
      );

      try {
        await saveDocument(
          {
            ...args,
            roomTemperatureImages,
            floorTemperatureImages,
            floorMoistureImages,
          },
          changeStep
        );
        if (finished) showFinishDocumentModal(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  const watchWasteDisposal = watch("wasteDisposal");

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: QualityField) => ({
    value: getValues(name),
    name,
    control,
  });

  const getFormUploadProps = (field: DocumentImageField) => {
    return {
      files: getValues(field) || [],
      control,
      setValue,
      ...register(field),
    };
  };

  return (
    <form onSubmit={preventDefault}>
      <ClosedQuestions
        title="Is er water op de werkplek aanwezig"
        {...values("waterAvailable")}
      />
      <ClosedQuestions
        title="Is er stroom op de werkplek aanwezig"
        {...values("electricityAvailable")}
      />
      <ClosedQuestions
        title="Is het werk op de begane grond?"
        onFalse
        {...values("onGroundFloor")}
      >
        <Box mb={2}>
          <Text fontSize="1.4rem">
            Indien werk op verdieping, hoe is het verticale transport geregeld?
          </Text>
        </Box>
        <FormInput
          label="Hoe is het verticale transport geregeld?"
          {...register("onGroundFloorComment")}
        />
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2} mt={2}>
          <FormInput
            label="Lift regelt klant middels"
            {...register("customerElevatorMessage")}
          />
          <FormInput
            label="Lift zelf regelen middels"
            {...register("ownElevatorMessage")}
          />
        </Grid>
      </ClosedQuestions>
      <ClosedQuestions
        title="Zijn er bijzonderheden voor horizontaal transport"
        onFalse
        onTrue={
          <Box flexDirection="row">
            <Box mr={2}>
              <Controller
                name="roadPlatesNecessary"
                control={control}
                render={(props) => {
                  return (
                    <FormCheckbox label="Rijplaten nodig" {...props.field} />
                  );
                }}
              />
            </Box>
            <Controller
              name="forkliftNecessary"
              control={control}
              render={(props) => {
                return <FormCheckbox label="Heftruck nodig" {...props.field} />;
              }}
            />
          </Box>
        }
        {...values("transportParticularities")}
      >
        <FormInput
          label="Toelichting"
          {...register("transportParticularitiesComment")}
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is er opslag aanwezig voor materiaal/gereedschap"
        {...values("storageAvailable")}
      >
        <FormInput
          label="Waar is deze opslag?"
          {...register("storageComment")}
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Zijn er derden aan het werk"
        {...values("thirdPartiesWorking")}
      >
        <FormInput
          label="Toelichting"
          {...register("thirdPartiesWorkingComment")}
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is er afstemming met derden nodig"
        {...values("thirdPartiesTuning")}
      >
        <FormInput
          label="Toelichting"
          {...register("thirdPartiesTuningComment")}
        />
      </ClosedQuestions>

      <Divider display={["none", "block"]} my="4rem" />

      <Box mb={4}>
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
          <FormSelect
            label="Hoe moet het afval worden afgevoerd?"
            options={wasteDisposalOptions}
            selected={!!watchWasteDisposal}
            {...register("wasteDisposal")}
          />
          {watchWasteDisposal === "OTHER" && (
            <FormInput
              label="Toelichting"
              {...register("wasteDisposalOther")}
            />
          )}
        </Grid>
      </Box>

      <Grid
        gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr"]}
        gridGap={2}
        mb={4}
      >
        <Controller
          control={control}
          name="roomTemperatureMeasuredAt"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Temperatuur vloer gemeten op"
            />
          )}
        />
        <FormInput
          label="Waarde is (C)"
          placeholder="Maak uw keuze"
          {...register("roomTemperatureValue")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("roomTemperatureImages")}
          defaultFilename="kamertemperatuur"
        />

        <Controller
          control={control}
          name="floorTemperatureMeasuredAt"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Temperatuur ruimte gemeten op"
            />
          )}
        />
        <FormInput
          label="Waarde is (C)"
          placeholder="Maak uw keuze"
          {...register("floorTemperatureValue")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("floorTemperatureImages")}
          defaultFilename="vloertemperatuur"
        />

        <Controller
          control={control}
          name="floorMoistureMeasuredAt"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Vochtigheid vloer gemeten op"
            />
          )}
        />
        <FormInput
          label="Waarde is (%)"
          placeholder="Maak uw keuze"
          {...register("floorMoistureValue")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("floorMoistureImages")}
          defaultFilename="vloervochtigheid"
        />
      </Grid>

      <ClosedQuestions
        title="Is de vloer vrijdragend geventileerd?"
        {...values("floorCantileveredVentilated")}
      />
      <ClosedQuestions
        title="Is de vloer vrij van losse delen?"
        {...values("floorNoLooseParts")}
      />
      <ClosedQuestions
        title="Is er een legplan aanwezig?"
        {...values("layingPlanAvailable")}
      />
      <ClosedQuestions
        title="Zijn de materialen geacclimatiseerd?"
        {...values("materialsAcclimated")}
      />
      <FormButtons
        onPrevious={handleSubmit((args) => onSubmit(args, -1))}
        onFinish={handleSubmit((args) => onSubmit(args, 0, true))}
        onSave={handleSubmit((args) => onSubmit(args, 0))}
      />
      <ShareDocumentModal
        show={finishDocumentModal}
        finished
        close={() => showFinishDocumentModal(false)}
      />
    </form>
  );
};

export default IntakeWorkspaceForm;
