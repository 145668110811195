import React, { useState } from "react";
import { useForm, Resolver } from "react-hook-form";
import { Box, Button, Text } from "../../styles";
import FormCheckbox from "./FormCheckbox";
import FormInput from "./FormInput";
import { auth, firestore } from "../../firebase/firebaseSetup";
import validateEmail from "../../lib/validateEmail";

type FormValues = {
  password: string;
  email: string;
};

type ErrorValues = {
  password?: object;
  email?: object;
};

const validatePassword = (password: string) => {
  return String(password).match(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
  );
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.email) {
    errors.email = { type: "required", message: "Dit veld is verplicht." };
  } else if (!validateEmail(values.email)) {
    errors.email = {
      type: "required",
      message: "Vul een geldig e-mailadres in.",
    };
  }
  if (!values.password) {
    errors.password = { type: "required", message: "Dit veld is verplicht." };
  } else if (!validatePassword(values.password)) {
    errors.password = {
      type: "required",
      message:
        "Dit veld vereist minstens 8 letters, waaronder een kleine en een grote letter, een cijfer en een speciaal teken.",
    };
  }
  return {
    values,
    errors,
  };
};

const RegisterForm = () => {
  const [invalidUser, setInvalidUser] = useState(false);
  const [policyCheck, setPolicyCheck] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      const response = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const uid = response.user?.uid;
      await firestore().collection("users").doc(uid).set({
        id: uid,
        email,
        created: firestore.FieldValue.serverTimestamp(),
      });
    } catch (e) {
      setInvalidUser(true);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {invalidUser ? (
        <Box bg="errorLight" p={16} borderRadius={10} mb={2}>
          <Text color="error">
            Kan geen account aanmaken met de ingevulde gegevens, omdat het
            account al bestaat of het niet voldoet aan de criteria. Probeer het
            opnieuw met andere gegevens.
          </Text>
        </Box>
      ) : null}
      <FormInput
        label="E-mail"
        {...register("email")}
        mb={2}
        error={errors?.email?.message}
      />
      <FormInput
        label="Password"
        type="password"
        {...register("password")}
        error={errors?.password?.message}
        mb={2}
      />
      <Box
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <FormCheckbox
          label="Ik ga akkoord met de algemene voorwaarden"
          checked={policyCheck}
          onChange={() => setPolicyCheck((prev) => !prev)}
        />
      </Box>
      <Button
        variant="primary"
        type="submit"
        mb={1}
        width="100%"
        disabled={!policyCheck}
      >
        Aanmelden
      </Button>
    </form>
  );
};

export default RegisterForm;
