import React, { useState } from "react";
import styled from "styled-components";
import { Box, Grid } from "../styles";
import AddDocumentButton from "./AddDocumentButton";
import documentKinds from "../lib/documentKinds";
import documentIcons from "../lib/documentIcons";
import CreateDocumentModal from "./CreateDocumentModal";

const DocumentBox = styled(Box)`
  svg {
    margin-right: 0;
  }
`;

const SuggestedDocuments = () => {
  const [createDocumentKind, setCreateDocumentKind] = useState<string>("");
  return (
    <>
      <Grid mt={1} mb={3}>
        {documentKinds.map(({ key, value, description }) => (
          <DocumentBox key={key}>
            <AddDocumentButton
              onClick={() => setCreateDocumentKind(key)}
              title={value}
              description={description}
            >
              {documentIcons[key]}
            </AddDocumentButton>
          </DocumentBox>
        ))}
      </Grid>
      <CreateDocumentModal
        show={!!createDocumentKind}
        close={() => setCreateDocumentKind("")}
        type={createDocumentKind}
      />
    </>
  );
};

export default SuggestedDocuments;
