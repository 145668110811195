import styled from "styled-components";
import Label from "./Label";

const CenteredLabel = styled(Label)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default CenteredLabel;
