import React, { FC, useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";
import { Box, Grid, Text } from "../styles";
import { firestore, firebase } from "../firebase/firebaseSetup";
import Document from "./Document";
import ActionDropdown from "./ActionDropdown";
import filterOptions from "../lib/filterOptions";
import sortOptions from "../lib/sortOptions";
import { DocumentType } from "../lib/types";

interface Props {
  search: string;
  setSearch: (value: string) => void;
}

const Documents: FC<Props> = ({ search }) => {
  const { user } = useAuth();
  const [documents, setDocuments] = useState<any | null>(null);
  const [filter, setFilter] = useState<string>("");
  const [sort, setSort] = useState<string>("updatedAt");

  const getDocuments = async () => {
    if (user) {
      const { order = "desc" } =
        sortOptions.find(({ key }) => key === sort) || {};
      let query = firestore()
        .collection("documents")
        .where("createdBy", "==", user.uid);

      if (search) {
        query = query
          .orderBy("nameInsensitive")
          .startAt(search.toLowerCase())
          .endAt(`${search.toLowerCase()}\uf8ff`);
      } else {
        query = query.orderBy(sort, order === "desc" ? "desc" : "asc");
      }

      if (filter) {
        query = query.where("type", "==", filter);
      }

      query.limit(10).onSnapshot((docSnapshot: any) => {
        const data: firebase.firestore.QuerySnapshot = docSnapshot.docs.map(
          (doc: any) => ({
            ...doc.data(),
            id: doc.id,
          })
        );
        setDocuments(data);
      });
    }
  };

  useEffect(() => {
    getDocuments();
  }, [search, sort, filter]);

  return (
    <>
      <Box mb="3rem">
        <Text fontSize="1.8rem" fontWeight="600">
          Documenten
        </Text>
      </Box>
      <Box mb="3rem" flexDirection="row" justifyContent="space-between">
        <ActionDropdown
          selected={filter}
          onChange={setFilter}
          title="Filter:"
          options={filterOptions}
        />
        <ActionDropdown
          selected={sort}
          onChange={setSort}
          title="Sorteren op:"
          options={sortOptions}
          right={0}
          left="auto"
        />
      </Box>
      <Grid mb={2}>
        {documents
          ? documents?.map((document: DocumentType) => (
              <Document key={document.id} document={document} />
            ))
          : null}
      </Grid>
    </>
  );
};

export default Documents;
