import React, { useMemo } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import { ErrorValues, FormValues } from "../../lib/types";
import { Grid } from "../../styles";
import FormButtons from "./FormButtons";
import FormDate from "./FormDate";
import FormInput from "./FormInput";

const validatePhone = (p: string) => {
  return p.replace(/\D/g, "");
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.customerName) {
    errors.customerName = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (values.contactPersonPhone && !validatePhone(values.contactPersonPhone)) {
    errors.contactPersonPhone = {
      type: "required",
      message: "Nummer is niet correct.",
    };
  }
  return {
    values,
    errors,
  };
};

const IntakeGeneralDataForm = () => {
  const {
    doc,
    saveDocument,
  }: {
    doc: any;
    saveDocument: (d: any, e: any) => void;
  } = useDocument();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        customerName: doc.customerName,
        location: doc.location,
        deliveryAddress: doc.deliveryAddress,
        locationParticularities: doc.locationParticularities,
        contactPersonName: doc.contactPersonName,
        contactPersonPhone: doc.contactPersonPhone,
        parking: doc.parking,
        executionDate: doc.executionDate?.toDate?.(),
        kind: doc.kind,
      };
    }, [doc]),
  });

  return (
    <form onSubmit={preventDefault}>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
        <FormInput
          label="Naam klant"
          error={errors?.customerName?.message}
          {...register("customerName")}
        />
        <FormInput label="Locatie" {...register("location")} />
        <FormInput
          label="Uitvoeringsadres (Straat, huisnummer)"
          {...register("deliveryAddress")}
        />
        <FormInput
          label="Bijzonderheden locatie (Bijv, toeganstijden, vergunning)"
          {...register("locationParticularities")}
        />
        <FormInput label="Contactpersoon" {...register("contactPersonName")} />
        <FormInput
          label="Telefoonnummer contactpersoon"
          {...register("contactPersonPhone")}
        />
        <FormInput label="Parkeren" {...register("parking")} />
        <Controller
          control={control}
          name="executionDate"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Uitvoeren op"
            />
          )}
        />
      </Grid>
      <FormButtons
        onPrevious={handleSubmit((args) => saveDocument(args, -1))}
        onNext={handleSubmit((args) => saveDocument(args, 1))}
        onSave={handleSubmit((args) => saveDocument(args, 0))}
      />
    </form>
  );
};

export default IntakeGeneralDataForm;
