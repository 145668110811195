import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CreateRoomModal from "../CreateRoomModal";
import { Box, Button } from "../../styles";
import { RoomTypes } from "../../lib/types";
import { ReactComponent as PlusIcon } from "../../assets/icon/plus-primary.svg";
import Room from "../Room";
import { firestore } from "../../firebase/firebaseSetup";
import FormButtons from "./FormButtons";
import { useDocument } from "../../context/DocumentContext";
import ShareDocumentModal from "../ShareDocumentModal";

const IntakeRoomsForm = () => {
  const { id } = useParams();
  const { doc } = useDocument();

  const [showCreateRoomModal, setShowCreateRoomModal] =
    useState<boolean>(false);
  const [finishDocumentModal, showFinishDocumentModal] =
    useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomTypes[]>([]);
  const [roomId, setRoomId] = useState<string>("");

  const getRooms = async () => {
    const data: any = await firestore()
      .collection(`documents/${id}/rooms`)
      .orderBy("updatedAt", "desc")
      .limit(10)
      .get();
    const docs = data.docs.map((_doc: any) => ({
      ..._doc.data(),
      id: _doc.id,
    }));
    setRooms(docs);
  };

  useEffect(() => {
    getRooms();
  }, []);

  const onSuccess = () => {
    setShowCreateRoomModal(false);
    getRooms();
  };

  const addRoom = (addedRoom: RoomTypes) => {
    setRooms([addedRoom, ...rooms]);
  };

  const updateRooms = (updatedRoom: RoomTypes) => {
    setRooms([
      updatedRoom,
      ...rooms.filter(({ id: _id }: { id: string }) => _id !== updatedRoom?.id),
    ]);
  };

  const removeRoom = (removedRoomId: string) => {
    setRooms(
      rooms.filter(({ id: _id }: { id: string }) => _id !== removedRoomId)
    );
  };

  const onSubmit = async (stepChange: number) => {
    if (id) {
      const data = {
        activeStep: doc.activeStep + stepChange,
        updatedAt: firestore.FieldValue.serverTimestamp(),
      };
      try {
        await firestore()
          .collection("documents")
          .doc(id)
          .set(data, { merge: true });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  return (
    <Box>
      {rooms?.map((room: RoomTypes) => (
        <Room
          active={room.id === roomId}
          room={room}
          setRoomId={setRoomId}
          addRoomToState={addRoom}
          updateRoomFromState={updateRooms}
          removeRoomFromState={removeRoom}
        />
      ))}
      <Button
        variant="active"
        onClick={() => setShowCreateRoomModal(true)}
        mt={[1, 3]}
        minHeight="5rem"
      >
        <PlusIcon />
        Nieuwe ruimte toevoegen
      </Button>
      <FormButtons
        onPrevious={() => onSubmit(-1)}
        onFinish={() => showFinishDocumentModal(true)}
        onSave={() => onSubmit(0)}
      />
      <CreateRoomModal
        close={() => setShowCreateRoomModal(false)}
        onSuccess={onSuccess}
        show={showCreateRoomModal}
      />
      <ShareDocumentModal
        show={finishDocumentModal}
        close={() => showFinishDocumentModal(false)}
        finished
      />
    </Box>
  );
};

export default IntakeRoomsForm;
