import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const {
  REACT_APP_API_KEY,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_PROJECT_ID,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_MESSAGE_SENDER_ID,
  REACT_APP_APP_ID,
} = process.env;

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_MESSAGE_SENDER_ID,
  appId: REACT_APP_APP_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");

microsoftProvider.setCustomParameters({
  prompt: "select_account",
});

const signInWithMicrosoft = () => auth.signInWithPopup(microsoftProvider);

const signInWithGoogle = () => auth.signInWithPopup(provider);
const { firestore } = firebase;
firestore().settings({
  ignoreUndefinedProperties: true,
});

const functions = firebase.app().functions("europe-west1");

export {
  firestore,
  functions,
  firebase,
  auth,
  signInWithGoogle,
  signInWithMicrosoft,
};
