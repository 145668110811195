import React, { FC, useMemo } from "react";
import { useForm, Resolver } from "react-hook-form";
import { Box, Button } from "../../styles";
import FormInput from "./FormInput";
import { firestore } from "../../firebase/firebaseSetup";
import { useAuth } from "../../context/AuthContext";
import { DocumentType } from "../../lib/types";

type FormValues = {
  name: string;
};

type ErrorValues = {
  name?: object;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: ErrorValues = {};
  if (!values.name) {
    errors.name = { type: "required", message: "Dit veld is verplicht." };
  } else if (values.name.length < 3) {
    errors.name = {
      type: "required",
      message: "Vul minstens 3 tekens in.",
    };
  }
  return {
    values,
    errors,
  };
};

interface Props {
  doc: DocumentType;
  close: () => void;
}

const UpdateDocumentForm: FC<Props> = ({ doc, close }) => {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        type: doc.type,
        name: doc.name,
      };
    }, [doc]),
  });

  const onSubmit = async ({ name }: FormValues) => {
    try {
      if (user) {
        await firestore().collection("documents").doc(doc.id).set(
          {
            name,
            nameInsensitive: name.toLowerCase(),
            updatedAt: firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        );
      }
      close();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box my={4}>
        <FormInput
          label="Formuliernaam"
          {...register("name")}
          mb={2}
          error={errors?.name?.message}
        />
      </Box>
      <Box flexDirection="row" justifyContent="flex-end">
        <Button
          variant="secondary"
          type="submit"
          ml={1}
          width={140}
          onClick={close}
        >
          Annuleren
        </Button>
        <Button variant="primary" type="submit" ml={1} width={140}>
          Aanpassen
        </Button>
      </Box>
    </form>
  );
};

export default UpdateDocumentForm;
