import styled from "styled-components";
import { border, color, flex, layout, space } from "styled-system";
import { devices } from "../lib/sizes";
import Box from "./Box";

const Block = styled(Box)`
  height: 80px;
  background-color: white;
  border-radius: 14px;
  width: 100%;
  border: 1px solid #eceeef;
  position: relative;

  @media ${devices.mobile} {
    height: 60px;
  }

  ${border}
  ${color}
  ${layout}
  ${space}
  ${flex}
`;

export default Block;
