import React, { FC } from "react";
import styled from "styled-components";
import { ReactComponent as WarningIcon } from "../assets/icon/warning.svg";
import Tooltip from "./ToolTip";

const WarningContainer = styled.div`
  position: absolute;
  right: 16px;
  height: 18px;
  margin-top: 2.5px;
  top: 50%;
  height: 18px;
  margin-top: 2.5px;
  transform: translateY(-50%);
  cursor: pointer;

  div {
    display: none;
  }

  &:hover {
    div {
      display: flex;
    }
  }
`;

interface Props {
  title: string;
}

const Warning: FC<Props> = ({ title }) => {
  return (
    <WarningContainer>
      <WarningIcon />
      <Tooltip title={title} />
    </WarningContainer>
  );
};

export default Warning;
