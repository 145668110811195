import { createContext, useContext } from "react";
import firebase from "firebase/compat/app";

type authContextType = {
  user: firebase.User | null;
  login: (email: string, password: string) => void;
  logout: () => void;
};

const authContextDefaultValues: authContextType = {
  user: null,
  login: () => null,
  logout: () => null,
};

const AuthContext = createContext<authContextType>(authContextDefaultValues);

function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext, useAuth };
