import React, { FC, ReactNode } from "react";
import { Box, Button } from "../styles";
import Hero from "./Hero";
import { ReactComponent as PlusIcon } from "../assets/icon/plus.svg";

interface Props {
  onClick: () => void;
  children: ReactNode;
  title: string;
  description: string;
}

const AddDocumentButton: FC<Props> = ({
  onClick,
  children,
  title,
  description,
}) => {
  return (
    <Button
      variant="secondary"
      height={["5rem", "7rem"]}
      px="2rem"
      onClick={onClick}
    >
      {children}
      <Box mx="2">
        <Hero
          title={title}
          description={description}
          fontSizeTitle="1.4rem"
          fontSizeDescription="1.2rem"
        />
      </Box>
      <Box ml="auto">
        <PlusIcon />
      </Box>
    </Button>
  );
};

export default AddDocumentButton;
