export default [
  {
    key: "sillsMaintained",
    value: "Dorpels blijven gehandhaafd in deuropeningen",
  },
  {
    key: "sillsRemoved",
    value: "Dorpels worden verwijderd tussen deuropeningen",
  },
  {
    key: "placingProfileConnected",
    value:
      "Plaatsen profiel i.v.m. overgang vloerafwerkingen, zie toelichting voor type",
  },
];
