import styled from "styled-components";
import { color, ColorProps } from "styled-system";

type Props = ColorProps;

const Circle = styled.div<Props>`
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  border-radius: 3rem;
  background-color: #dde0e9;
  color: #fff;
  font-size: 1.7rem;
  padding-bottom: 0.1rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;

  ${color}
`;

export default Circle;
