import { createContext, useContext } from "react";

type documentContextType = {
  doc: any;
  activeStep: number;
  type: string;
  steps: string[];
  setStep: (n: number) => void;
  showSendDocumentModal: (n: boolean) => void;
  setDocument: (doc: any) => void;
  saveDocument: (doc: any, changeStep: number) => any;
};

const documentContextDefaultValues: documentContextType = {
  doc: null,
  activeStep: 0,
  type: "INTAKE",
  steps: [],
  setStep: () => null,
  showSendDocumentModal: () => null,
  setDocument: () => null,
  saveDocument: () => null,
};

const DocumentContext = createContext<documentContextType>(
  documentContextDefaultValues
);

function useDocument() {
  return useContext(DocumentContext);
}

export { DocumentContext, useDocument, documentContextType };
