import React from "react";
import { Hero, RegisterForm } from "../component";
import { Box, NavLink, Text } from "../styles";

const RegisterScreen = () => {
  return (
    <Box width={400} maxWidth="100%">
      <Box m="0 0 5rem 0">
        <Hero
          title="Aanmelden"
          description="Welkom! Vul hieronder de gegevens voor uw nieuwe account in."
        />
      </Box>
      <RegisterForm />
      <Box flexDirection="row" justifyContent="center">
        <Text fontSize="1.4rem" color="disabled" mr={1}>
          Heb je al een account?
        </Text>
        <NavLink to="/">Log in</NavLink>
      </Box>
    </Box>
  );
};

export default RegisterScreen;
