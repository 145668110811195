import { createContext, useContext } from "react";

type appContextType = {
  createImage: (id: string) => void;
};

const appContextDefaultValues: appContextType = {
  createImage: () => null,
};

const AppContext = createContext<appContextType>(appContextDefaultValues);

function useApp() {
  return useContext(AppContext);
}

export { AppContext, useApp };
