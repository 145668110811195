const documentKinds = [
  { value: "Opnameformulier", key: "INTAKE", description: "Werkzaamheden" },
  {
    value: "Werk inspectie",
    key: "WORK_INSPECTION",
    description: "Veiligheid en kwaliteit",
  },
  {
    value: "Oplevering",
    key: "DELIVERY",
    description: "Nacalculatie en kwaliteit",
  },
];

export default documentKinds;
