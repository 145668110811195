import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
import { firebase } from "../firebase/firebaseSetup";

const compressFile = (file: File) => {
  /* eslint-disable no-new */
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      // maxHeight: 700,
      // maxWidth: 700,
      quality: 0.4,
      success: (result) => {
        const compressedFile: File = new File([result], file.name, {
          type: file.type,
        });
        resolve(compressedFile);
      },
      error: (e) => reject(e),
    });
  });
};

const uploadFile = async (file: File) => {
  const compressedFile: any = await compressFile(file);
  const image = await firebase.storage().ref(uuidv4()).put(compressedFile);
  return image.ref.getDownloadURL();
};

export default uploadFile;
