import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useRoom } from "../../context/RoomContext";
import { RoomTypes } from "../../lib/types";
import undergroundCheckFields from "../../lib/undergroundCheckFields";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Button, Grid } from "../../styles";
import CheckBlock from "../CheckBlock";
import FormInput from "./FormInput";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const RoomUndergroundForm = () => {
  const { saveRoom } = useRoom();

  const { room } = useRoom();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { isDirty },
    watch,
    setValue,
    reset,
  } = useForm<RoomTypes>({
    defaultValues: useMemo(() => {
      return {
        newUnderground: room.newUnderground,
        depositedOnSand: room.depositedOnSand,
        ventilatedCrawlSpace: room.ventilatedCrawlSpace,
        unventilatedCrawlSpace: room.unventilatedCrawlSpace,
        anhydrite: room.anhydrite,
        tileFloor: room.tileFloor,
        curingCompound: room.curingCompound,
        existingFloors: room.existingFloors,
        sandCement: room.sandCement,
        wood: room.wood,
        monolith: room.monolith,
        undergroundOther: room.undergroundOther,
        moisturePercentage: room.moisturePercentage,
        undergroundComment: room.undergroundComment,
        undergroundImages: room.undergroundImages,
      };
    }, [room]),
  });

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: any) => ({
    value: getValues(name),
    name,
    control,
  });

  const onSubmit = async (args: RoomTypes) => {
    const undergroundImages = await updateAndGetFiles(
      room.undergroundImages,
      args.undergroundImages
    );
    const onSuccess = await saveRoom({ ...args, undergroundImages });
    if (onSuccess) reset(getValues());
  };

  const getFormUploadProps = () => {
    return {
      files: getValues("undergroundImages") || [],
      control,
      setValue,
      ...register("undergroundImages"),
    };
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box bg="white">
        <Grid
          gridTemplateColumns={["1fr", "1fr 1fr"]}
          gridColumnGap={2}
          gridRowGap={1}
        >
          {undergroundCheckFields.map(({ key, value }) => (
            <CheckBlock key={key} label={value} {...values(key)} />
          ))}
        </Grid>
        <Box my={2}>
          <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
            <FormInput
              label="Anders namelijk:"
              {...register("undergroundOther")}
            />
            <FormInput
              label="Wat is het vochtpercentage?"
              {...register("moisturePercentage")}
            />
          </Grid>
        </Box>
        <Box mb={2}>
          <FormTextarea
            label="Toelichting"
            {...register("undergroundComment")}
          />
        </Box>
        <FormUpload
          {...getFormUploadProps()}
          full
          defaultFilename={`${room.name || "kamer"}-ondergrond`}
        />
        <Button variant="primary" type="submit" my={4} disabled={!isDirty}>
          Opslaan
        </Button>
      </Box>
    </form>
  );
};

export default RoomUndergroundForm;
