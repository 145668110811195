export const windowProductOptions = [
  { key: "ROLLER_BLIND", value: "Rolgordijn" },
  { key: "PLEATED", value: "Plisse" },
  { key: "VENETIAN_BLIND", value: "Jaloezie" },
  { key: "SLAT_CURTAIN", value: "Lamel" },
];

export const windowMountOptions = [
  { key: "CEILING", value: "Plafond" },
  { key: "WALL", value: "Wand" },
];

export const windowPackageOptions = [
  { key: "L", value: "L" },
  { key: "R", value: "R" },
  { key: "M", value: "M" },
];
export const windowSideOperationOptions = [
  { key: "LEFT", value: "Links" },
  { key: "RIGHT", value: "Rechts" },
];
export const windowFrameOptions = [
  { key: "WOOD", value: "Hout" },
  { key: "PLASTIC", value: "Kunststof" },
  { key: "ALUMINIUM", value: "Alu" },
];

export const hasWindowSupportDistanceOptions = [
  { key: "YES", value: "Ja" },
  { key: "NO", value: "Nee" },
];
