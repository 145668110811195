import React, {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useState,
} from "react";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { ReactComponent as CrossIcon } from "../../assets/icon/cross.svg";
import { ReactComponent as ImgIcon } from "../../assets/icon/img.svg";
import { ImageCover, Box, Button, Label, Text } from "../../styles";
import { ExtendedFileType, FileType } from "../../lib/types";
import theme from "../../lib/theme";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  defaultFilename?: string;
  name: string;
  files: Array<FileType>;
  setValue: any;
  control: any;
  full?: boolean;
}

const RemoveButton = styled.button`
  all: unset;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 1rem;
`;

const FormUpload: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, files, name, setValue, control, full, defaultFilename },
  ref
) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [dragEnter, setDragEnter] = useState<boolean>(false);

  const handleRemove = (i: number) => {
    setDisabled(true);
    setValue(
      name,
      [...files].filter((_: any, idx: number) => idx !== i)
    );
    setTimeout(() => setDisabled(false), 300);
  };
  const uploadId = `upload-${name}`;

  const handleClick = () => {
    const input = document.getElementById(uploadId);
    if (input) input.click();
  };

  const getFileNumber = (increment: number) => {
    const numbers = [...files].map(
      (file) => +file.name.split(".")[0].slice(-2)
    );
    return numbers.length && Math.max(...numbers)
      ? `0${Math.max(...numbers) + increment}`.slice(0, 2)
      : `0${increment}`.slice(0, 2);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={(props) => {
        const handleChange = (e: any) => {
          e.preventDefault();
          props.field.onChange([
            ...files,
            ...[...((e.dataTransfer as any) || (e.target as any)).files].map(
              (file: ExtendedFileType, idx: number) => {
                const [filename, extension] = file.name.split(".");
                return {
                  name: `${defaultFilename || filename}${getFileNumber(
                    idx + 1
                  )}.${extension}`,
                  size: file.size,
                  type: file.type,
                  url: URL.createObjectURL(file),
                  file,
                };
              }
            ),
          ]);
        };

        return (
          <Box width="100%">
            <Label style={{ pointerEvents: "none" }}>
              {label}
              <Box flexDirection="row" flexWrap="wrap">
                {[...files].map((file: FileType, i) => (
                  <Box
                    key={file.name + file.url}
                    flexDirection="row"
                    pr={2}
                    position="relative"
                  >
                    <Box
                      width={50}
                      height={50}
                      borderRadius={1}
                      overflow="hidden"
                      mb={full ? 2 : 0}
                    >
                      <ImageCover src={file.url} alt={label} />
                    </Box>
                    <RemoveButton
                      disabled={disabled}
                      style={{ pointerEvents: "auto" }}
                      onClick={() => handleRemove(i)}
                    >
                      <CrossIcon fill="#10182F" />
                    </RemoveButton>
                  </Box>
                ))}
                <Button
                  variant="upload"
                  type="button"
                  bg={dragEnter ? "white" : "primaryLight"}
                  disabled={disabled}
                  onClick={handleClick}
                  width={full ? "100%" : "5rem"}
                  height={full ? "7rem" : "5rem"}
                  onDragEnter={() => setDragEnter(true)}
                  onDragOver={(e: React.MouseEvent) => e.preventDefault()}
                  onDragLeave={() => setDragEnter(false)}
                  onDrop={handleChange}
                  style={{ whiteSpace: "normal", pointerEvents: "auto" }}
                >
                  {full ? (
                    <Box
                      flexDirection="row"
                      width="100%"
                      justifyContent="center"
                      alignItems="center"
                      style={{ pointerEvents: "none" }}
                    >
                      <Box mr={1} width={["2rem", "3rem"]}>
                        <ImgIcon />
                      </Box>
                      <Text
                        fontSize="1.4rem"
                        color="disabled"
                        display={["none", "block"]}
                      >
                        Sleep hier een afbeelding naartoe, of{" "}
                        <span style={{ color: theme.colors.primary }}>
                          klik om te uploaden
                        </span>
                      </Text>
                      <Text
                        fontSize="1.4rem"
                        color="disabled"
                        display={["block", "none"]}
                      >
                        <span style={{ color: theme.colors.primary }}>
                          Klik om een foto te maken of uploaden
                        </span>
                      </Text>
                    </Box>
                  ) : (
                    "+"
                  )}
                </Button>
              </Box>
            </Label>

            <input
              type="file"
              id={uploadId}
              multiple
              hidden
              accept="image/png,image/jpg,image/jpeg"
              ref={ref}
              onChange={handleChange}
            />
          </Box>
        );
      }}
    />
  );
};

export default React.forwardRef(FormUpload);
