export const curtainNewRailOptions = [
  { key: "YES", value: "Ja" },
  { key: "NO", value: "Nee" },
];

export const curtainSetOrPieceOptions = [
  { key: "PIECE", value: "Stuk" },
  { key: "SET", value: "Stel" },
];

export const curtainMountedOnOptions = [
  { key: "PLASTER", value: "Gips" },
  { key: "WOOD", value: "Hout" },
  { key: "SYSTEM", value: "Systeem" },
  { key: "CONCRETE", value: "Beton" },
  { key: "FRAME", value: "Kozijn" },
];
export const curtainMountedTimeOptions = [
  { key: "IN_THE_DAY", value: "In de dag" },
  { key: "ON_THE_DAY", value: "Op de dag" },
];
export const curtainPleatTypeOptions = [
  { key: "SINGLE", value: "Enkel" },
  { key: "DOUBLE", value: "Dubbel" },
];

export const hasCurtainSupportDistanceOptions = [
  { key: "YES", value: "Ja" },
  { key: "NO", value: "Nee" },
];
