import { createContext, useContext } from "react";

type roomContextType = {
  room: any;
  saveRoom: (room: any) => any;
};

const roomContextDefaultValues: roomContextType = {
  room: null,
  saveRoom: () => null,
};

const RoomContext = createContext<roomContextType>(roomContextDefaultValues);

function useRoom() {
  return useContext(RoomContext);
}

export { RoomContext, useRoom, roomContextType };
