import React, { FC, useState } from "react";
import { PositionProps } from "styled-system";
import { Box, Button, Text } from "../styles";
import { ReactComponent as ArrowDownIcon } from "../assets/icon/arrow-down.svg";
import Dropdown from "./Dropdown";

interface Props extends PositionProps {
  title: string;
  selected: string;
  onChange: (option: string) => void;
  options: Array<any>;
}

const ActionDropdown: FC<Props> = ({
  title,
  selected,
  onChange,
  options,
  ...props
}) => {
  const [showActions, setShowActions] = useState(false);

  const toggle = () => setShowActions((prev) => !prev);

  const handleChange = (key: string) => {
    toggle();
    onChange(key);
  };

  return (
    <Box flexDirection="row" alignItems="center">
      <Text color="disabled" fontSize={["1.2rem", "1.4rem"]}>
        {title}
      </Text>
      <Box position="relative">
        <Button variant="transparent" onClick={toggle} height={20}>
          <Box mx="0.5rem">
            <Text fontSize={["1.2rem", "1.4rem"]}>
              {options.find(({ key }) => key === selected)?.value}
            </Text>
          </Box>
          <ArrowDownIcon />
        </Button>
        <Dropdown
          show={showActions}
          hide={() => setShowActions(false)}
          onChange={handleChange}
          options={options}
          {...props}
        />
      </Box>
    </Box>
  );
};

export default ActionDropdown;
