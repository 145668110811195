import React from "react";
import { Button } from "../styles";
import { signInWithMicrosoft } from "../firebase/firebaseSetup";
import { ReactComponent as MicrosoftIcon } from "../assets/icon/microsoft-icon.svg";

const MicrosoftSignInButton = () => {
  return (
    <Button variant="secondary" onClick={signInWithMicrosoft} mb={3}>
      <MicrosoftIcon />
      Sign in with Microsoft
    </Button>
  );
};

export default MicrosoftSignInButton;
