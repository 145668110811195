import React, { useEffect, useMemo } from "react";
import { Resolver, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  WorkInspectionSafetyErrorValues,
  SafetyField,
  SafetyImageField,
  DocumentType,
} from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import { Box, Divider, Grid, Text } from "../../styles";
import ClosedQuestions from "../ClosedQuestions";
import FormButtons from "./FormButtons";
import FormInput from "./FormInput";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const resolver: Resolver<DocumentType> = async (values) => {
  const errors: WorkInspectionSafetyErrorValues = {};
  if (
    values.safeWorkEnvironment === false &&
    !values.safeWorkEnvironmentImages
  ) {
    errors.safeWorkEnvironmentImages = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (
    values.safeWorkEnvironment === false &&
    !values.safeWorkEnvironmentComment
  ) {
    errors.safeWorkEnvironmentComment = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (values.EHBOToolsAvailable && !values.EHBOToolsAvailableComment) {
    errors.EHBOToolsAvailableComment = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.electricToolsCheckImages) {
    errors.electricToolsCheckImages = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  if (!values.materialAndToolsOrigin) {
    errors.materialAndToolsOrigin = {
      type: "required",
      message: "Dit veld is verplicht.",
    };
  }
  return {
    values,
    errors,
  };
};

const WorkInspectionSafetyForm = () => {
  const { doc, saveDocument } = useDocument();
  const { id } = useParams();

  const {
    handleSubmit,
    getValues,
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<DocumentType>({
    resolver,
    defaultValues: useMemo(() => {
      return {
        safeWorkEnvironment: doc.safeWorkEnvironment,
        safeWorkEnvironmentImages: doc.safeWorkEnvironmentImages,
        safeWorkEnvironmentComment: doc.safeWorkEnvironmentComment,
        deviatingRisks: doc.deviatingRisks,
        deviatingRisksComment: doc.deviatingRisksComment,
        safetyInstructionsGiven: doc.safetyInstructionsGiven,
        safetyInstructionsGivenComment: doc.safetyInstructionsGivenComment,
        EHBOToolsAvailable: doc.EHBOToolsAvailable,
        EHBOToolsAvailableComment: doc.EHBOToolsAvailableComment,
        cuttingRisks: doc.cuttingRisks,
        cuttingRisksComment: doc.cuttingRisksComment,
        electricToolsCheckMonth: doc.electricToolsCheckMonth,
        electricToolsCheckComment: doc.electricToolsCheckComment,
        electricToolsCheckImages: doc.electricToolsCheckImages,
        pbmDescription: doc.pbmDescription,
        materialAndToolsOrigin: doc.materialAndToolsOrigin,
      };
    }, [doc]),
  });

  const onSubmit = async (args: DocumentType, changeStep: number) => {
    if (id) {
      const safeWorkEnvironmentImages = await updateAndGetFiles(
        doc.safeWorkEnvironmentImages || [],
        args.safeWorkEnvironmentImages || []
      );
      const electricToolsCheckImages = await updateAndGetFiles(
        doc.electricToolsCheckImages || [],
        args.electricToolsCheckImages || []
      );

      try {
        await saveDocument(
          {
            ...args,
            safeWorkEnvironmentImages,
            electricToolsCheckImages,
          },
          changeStep
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: SafetyField) => ({
    value: getValues(name),
    name,
    control,
  });

  const getFormUploadProps = (field: SafetyImageField) => {
    return {
      files: getValues(field) || [],
      control,
      setValue,
      ...register(field),
    };
  };

  return (
    <form onSubmit={preventDefault}>
      <ClosedQuestions
        title="Is de werkplek dusdanig dat er veilig gewerkt kan worden?"
        onFalse
        {...values("safeWorkEnvironment")}
      >
        <FormInput
          label="Toelichting"
          mb={2}
          {...register("safeWorkEnvironmentComment")}
        />
        <FormUpload
          full
          {...getFormUploadProps("safeWorkEnvironmentImages")}
          defaultFilename="vloertemperatuur"
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Zijn er afwijkende risico's, zijn deze vastgelegd en bekend bij de stoffeerders?"
        {...values("deviatingRisks")}
      >
        <FormInput label="Toelichting" {...register("deviatingRisksComment")} />
      </ClosedQuestions>
      <ClosedQuestions
        title="Is er voor dit project een toolbox of veiligheidsinstructie gegeven?"
        {...values("safetyInstructionsGiven")}
      >
        <FormInput
          label="Toelichting (noteer wie daarbij aanwezig waren)"
          {...register("safetyInstructionsGivenComment")}
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Zijn er EHBO middelen binnen handbereik?"
        {...values("EHBOToolsAvailable")}
      >
        <FormInput
          label="Toelichting (noteer waar de EHBO middelen zijn)"
          {...register("EHBOToolsAvailableComment")}
        />
      </ClosedQuestions>
      <ClosedQuestions
        title="Is er een risico op snijwonden?"
        {...values("cuttingRisks")}
      >
        <FormInput
          label="Toelichting (hoe wordt de kans op een ongeval beperkt?)"
          {...register("cuttingRisksComment")}
        />
      </ClosedQuestions>

      <Divider display={["none", "block"]} my="4rem" />

      <Box mb={2}>
        <Text fontSize="1.4rem">
          Wanneer moet het electrische gereedschap gekeurd worden?
        </Text>
      </Box>
      <Grid gridTemplateColumns={["1fr", "1fr 3fr 1fr"]} gridGap={2}>
        <FormInput label="Maand" {...register("electricToolsCheckMonth")} />
        <FormInput
          label="Toelichting"
          {...register("electricToolsCheckComment")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("electricToolsCheckImages")}
          defaultFilename="keuring-e-gereedschap"
        />
      </Grid>
      <Divider display={["none", "block"]} my="4rem" />

      <Box my={2}>
        <FormTextarea
          label="Welke pbm's worden gebruikt en waarom?"
          {...register("pbmDescription")}
        />
      </Box>
      <FormTextarea
        label="Hoe is het materiaal en gereedschap op de werkplek gekomen?"
        error={errors?.materialAndToolsOrigin?.message}
        {...register("materialAndToolsOrigin")}
      />
      <FormButtons
        onPrevious={handleSubmit((args: DocumentType) => onSubmit(args, -1))}
        onNext={handleSubmit((args: DocumentType) => onSubmit(args, 1))}
        onSave={handleSubmit((args: DocumentType) => onSubmit(args, 0))}
      />
    </form>
  );
};

export default WorkInspectionSafetyForm;
