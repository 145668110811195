import styled from "styled-components";
import { grid, GridProps, space, SpaceProps } from "styled-system";
import { devices } from "../lib/sizes";

interface Props extends SpaceProps, GridProps {}

const Grid = styled.div<Props>`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media ${devices.tablet} {
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }

  @media ${devices.mobile} {
    grid-template-columns: 1fr;
  }

  ${space}
  ${grid}
`;

export default Grid;
