import React, { FC } from "react";
import styled from "styled-components";
import { devices } from "../lib/sizes";
import { Box, Text } from "../styles";
import ProgressRing from "./ProgressRing";

interface Props {
  steps: Array<string>;
  step: number;
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 4rem 0;

  @media ${devices.mobile} {
    margin: 3rem 0;
  }
`;

const RelativeContainer = styled.div`
  position: relative;
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
`;

const AbsoluteFlexContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4rem;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 4rem;
  height: 4rem;
  color: white;
  font-size: 26px;
`;

const documentDescriptions = [
  "Informatie met betrekking tot het formulier",
  "Aanvullende gegevens met betrekking tot de klant",
  "Aanvullende gegevens met betrekking tot de werkplek",
  "Voeg ruimtes toe en afhankelijk van het type de aanvullende gegevens",
];

const Stepper: FC<Props> = ({ steps, step }) => {
  const stepLength = steps.length;
  return (
    <FlexContainer>
      <RelativeContainer>
        <AbsoluteContainer>
          <ProgressRing
            radius={28}
            stroke={2}
            progress={step * (100 / stepLength)}
            strokeColor="rgba(46, 121, 241, 1)"
          />
        </AbsoluteContainer>
        <AbsoluteFlexContainer>
          <Step>{step}</Step>
        </AbsoluteFlexContainer>
        <ProgressRing
          radius={28}
          stroke={2}
          progress={100}
          strokeColor="rgba(46, 121, 241, 0.1)"
        />
      </RelativeContainer>
      <Box ml={1}>
        <Text fontSize={["2.4rem", "3.2rem"]} fontWeight="600">
          {steps[step - 1]}
        </Text>
        <Text fontSize={["1.4rem", "1.6rem"]} color="disabled">
          {documentDescriptions[step - 1]}
        </Text>
      </Box>
    </FlexContainer>
  );
};

export default Stepper;
