import React, { FC } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { Box, Label } from "../../styles";
import { ReactComponent as CalendarIcon } from "../../assets/icon/calendar.svg";
import Warning from "../Warning";

const days = ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
const months = [
  "Januari",
  "Februari",
  "Maart",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Augustus",
  "September",
  "Oktober",
  "November",
  "December",
];

const locale: object = {
  localize: {
    day: (n: number) => days[n],
    month: (n: number) => months[n],
  },
  formatLong: {
    date: () => "mm/dd/yyyy",
  },
};

interface Props {
  selected: Date;
  name?: string;
  label?: string;
  error?: string;
  onChange: (selected: Date) => void;
}

const DateLabel = styled(Label)`
  position: relative;

  input {
    border: 1px solid;
    height: 5rem;
    border-radius: 1rem;
    width: 100%;
    padding-left: 1.6rem;
    font-size: 1.6rem;
    margin-top: ${({ title }) => (title ? "5px" : "0px !important")};

    &::placeholder {
      color: #b3bad0;
    }

    border-color: ${(props) =>
      props.error ? props.theme.colors.error : props.theme.colors.secondary};
    background-color: ${(props) =>
      props.error ? props.theme.colors.errorLight : "#fff"};

    &:focus {
      border-color: ${(props) => props.theme.colors.primary};
      background-color: #e8f1ff;
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
`;

const FormDate: FC<Props> = ({ label, name, error, ...props }) => {
  return (
    <DateLabel htmlFor={name} error={error} title={label}>
      {label || null}
      <Box>
        <DatePicker
          locale={locale}
          dateFormat="dd-MM-yyyy"
          placeholderText="Selecteer datum"
          {...props}
        />
        <IconContainer>
          {error ? (
            <Warning title={error} />
          ) : (
            <Box mr="1.5rem">
              <CalendarIcon />
            </Box>
          )}
        </IconContainer>
      </Box>
    </DateLabel>
  );
};

export default FormDate;
