import React, { FC } from "react";
import styled from "styled-components";
import { Text } from "../styles";

interface Props {
  title: string;
}

const TooltipContainer = styled.div`
  background-color: #eb5757;
  position: absolute;
  bottom: calc(100% + 7px);
  right: -17px;
  border-radius: 0.3rem;
  height: 5rem;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
`;

const Arrow = styled.div`
  background-color: #eb5757;
  transform: rotate(45deg);
  position: absolute;
  top: calc(100% - 7px);
  right: 20px;
  width: 14px;
  height: 14px;
  border-radius: 3px;
`;

const ToolTip: FC<Props> = ({ title }) => {
  return (
    <TooltipContainer>
      <Text fontSize="1.4rem" color="white">
        {title}
      </Text>
      <Arrow />
    </TooltipContainer>
  );
};

export default ToolTip;
