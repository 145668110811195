import React, { FC } from "react";
import documentIcons from "../lib/documentIcons";
import documentKinds from "../lib/documentKinds";
import { DocumentType } from "../lib/types";
import { Box, Text } from "../styles";
import UpdateDocumentForm from "./form/UpdateDocumentForm";
import Modal from "./Modal";

interface Props {
  show: boolean;
  close: () => void;
  doc: DocumentType;
}

const UpdateDocumentModal: FC<Props> = ({ show, close, doc }) => {
  return (
    <Modal close={close} show={show}>
      <Box p={["0", "2rem"]} width={["100%", "48rem"]}>
        <Text fontSize="2.4rem" fontWeight="600">
          {documentIcons[doc.type]}
          <Box display="inline-block" ml={1} mr="0.5rem">
            {documentKinds.find(({ key }) => key === doc.type)?.value}
          </Box>
          aanpassen
        </Text>
        <UpdateDocumentForm close={close} doc={doc} />
      </Box>
    </Modal>
  );
};

export default UpdateDocumentModal;
