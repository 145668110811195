import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDocument } from "../../context/DocumentContext";
import preventDefault from "../../lib/preventDefault";
import {
  DocumentImageField,
  WorkspaceField,
  DocumentType,
} from "../../lib/types";
import updateAndGetFiles from "../../lib/updateAndGetFiles";
import wasteDisposalOptions from "../../lib/wasteDisposalOptions";
import { Box, Divider, Grid, Text } from "../../styles";
import ClosedQuestions from "../ClosedQuestions";
import FormButtons from "./FormButtons";
import FormCheckbox from "./FormCheckbox";
import FormDate from "./FormDate";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormTextarea from "./FormTextarea";
import FormUpload from "./FormUpload";

const IntakeWorkspaceForm = () => {
  const { doc, saveDocument } = useDocument();
  const { id } = useParams();

  const { handleSubmit, getValues, register, setValue, control, watch } =
    useForm<DocumentType>({
      defaultValues: useMemo(() => {
        return {
          deviatingRisks: doc.deviatingRisks,
          vgNecessary: doc.vgNecessary,
          workplaceAccessible: doc.workplaceAccessible,
          waterAvailable: doc.waterAvailable,
          conversationLog: doc.conversationLog,
          workspaceComment: doc.workspaceComment,
          transportParticularities: doc.transportParticularities,
          roadPlatesNecessary: doc.roadPlatesNecessary,
          forkliftNecessary: doc.forkliftNecessary,
          customerElevatorMessage: doc.customerElevatorMessage,
          ownElevatorMessage: doc.ownElevatorMessage,
          onGroundFloorComment: doc.onGroundFloorComment,
          onGroundFloor: doc.onGroundFloor,
          storageAvailable: doc.storageAvailable,
          storageComment: doc.storageComment,
          thirdPartiesWorking: doc.thirdPartiesWorking,
          transportParticularitiesComment: doc.transportParticularitiesComment,
          thirdPartiesWorkingComment: doc.thirdPartiesWorkingComment,
          thirdPartiesTuning: doc.thirdPartiesTuning,
          workplaceAccessibleComment: doc.workplaceAccessibleComment,
          waterAvailableComment: doc.waterAvailableComment,
          thirdPartiesTuningComment: doc.thirdPartiesTuningComment,
          wasteDisposal: doc.wasteDisposal,
          wasteDisposalOther: doc.wasteDisposalOther,
          roomTemperatureMeasuredAt: doc.roomTemperatureMeasuredAt?.toDate?.(),
          roomTemperatureValue: doc.roomTemperatureValue,
          roomTemperatureImages: doc.roomTemperatureImages,
          floorTemperatureMeasuredAt:
            doc.floorTemperatureMeasuredAt?.toDate?.(),
          floorTemperatureValue: doc.floorTemperatureValue,
          floorTemperatureImages: doc.floorTemperatureImages,
          floorMoistureMeasuredAt: doc.floorMoistureMeasuredAt?.toDate?.(),
          floorMoistureValue: doc.floorMoistureValue,
          floorMoistureImages: doc.floorMoistureImages,
        };
      }, [doc]),
    });

  const watchWasteDisposal = watch("wasteDisposal");

  const onSubmit = async (args: DocumentType, changeStep: number) => {
    if (id) {
      const roomTemperatureImages = await updateAndGetFiles(
        doc.roomTemperatureImages,
        args.roomTemperatureImages
      );
      const floorTemperatureImages = await updateAndGetFiles(
        doc.floorTemperatureImages,
        args.floorTemperatureImages
      );
      const floorMoistureImages = await updateAndGetFiles(
        doc.floorMoistureImages,
        args.floorMoistureImages
      );

      try {
        saveDocument(
          {
            ...args,
            roomTemperatureImages,
            floorTemperatureImages,
            floorMoistureImages,
          },
          changeStep
        );
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  useEffect(() => {
    watch();
  }, [watch]);

  const values = (name: WorkspaceField) => ({
    value: getValues(name),
    name,
    control,
  });

  const getFormUploadProps = (field: DocumentImageField) => {
    return {
      files: getValues(field) || [],
      control,
      setValue,
      ...register(field),
    };
  };

  return (
    <form onSubmit={preventDefault}>
      <ClosedQuestions
        title="Zijn er afwijkende risico's?"
        warning="Let op, TRA opstellen"
        {...values("deviatingRisks")}
      />

      <ClosedQuestions
        title="Moet er een V&G plan worden opgesteld?"
        warning="Let op, V&G plan opstellen"
        {...values("vgNecessary")}
      />

      <ClosedQuestions
        title="Is de werkplek vrij toegankelijk?"
        onFalse
        {...values("workplaceAccessible")}
      >
        <FormInput
          label="Toelichting"
          {...register("workplaceAccessibleComment")}
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is er water op de werkplek aanwezig?"
        onFalse
        {...values("waterAvailable")}
      >
        <FormInput label="Toelichting" {...register("waterAvailableComment")} />
      </ClosedQuestions>

      <ClosedQuestions
        title="Zijn er bijzonderheden voor horizontaal transport?"
        {...values("transportParticularities")}
      >
        <Box flexDirection="row" mb={2}>
          <Box mr={2}>
            <Controller
              name="roadPlatesNecessary"
              control={control}
              render={(props) => {
                return (
                  <FormCheckbox label="Rijplaten nodig" {...props.field} />
                );
              }}
            />
          </Box>
          <Controller
            name="forkliftNecessary"
            control={control}
            render={(props) => {
              return <FormCheckbox label="Heftruck nodig" {...props.field} />;
            }}
          />
        </Box>
        <FormInput
          label="Toelichting"
          {...register("transportParticularitiesComment")}
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is het werk op de begane grond?"
        onFalse
        {...values("onGroundFloor")}
      >
        <Box mb={2}>
          <Text fontSize="1.4rem">
            Indien werk op verdieping, hoe is het verticale transport geregeld?
          </Text>
        </Box>
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2} mb={2}>
          <FormInput
            label="Lift regelt klant middels"
            {...register("customerElevatorMessage")}
          />
          <FormInput
            label="Lift zelf regelen middels"
            {...register("ownElevatorMessage")}
          />
        </Grid>
        <FormInput label="Toelichting" {...register("onGroundFloorComment")} />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is er opslag aanwezig voor materiaal/gereedschap?"
        {...values("storageAvailable")}
      >
        <FormInput label="Toelichting" {...register("storageComment")} />
      </ClosedQuestions>

      <ClosedQuestions
        title="Zijn er derden aan het werk?"
        {...values("thirdPartiesWorking")}
      >
        <FormInput
          label="Toelichting"
          {...register("thirdPartiesWorkingComment")}
        />
      </ClosedQuestions>

      <ClosedQuestions
        title="Is er afstemming met derden nodig?"
        {...values("thirdPartiesTuning")}
      >
        <FormInput
          label="Toelichting"
          {...register("thirdPartiesTuningComment")}
        />
      </ClosedQuestions>

      <Divider display={["none", "block"]} mb="4rem" mt="1rem" />

      <Box mb={4}>
        <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2}>
          <FormSelect
            label="Hoe moet het afval worden afgevoerd?"
            options={wasteDisposalOptions}
            selected={!!watchWasteDisposal}
            {...register("wasteDisposal")}
          />
          {watchWasteDisposal === "OTHER" && (
            <FormInput
              label="Toelichting"
              {...register("wasteDisposalOther")}
            />
          )}
        </Grid>
      </Box>

      <Grid gridTemplateColumns={["1fr", "1fr", "1fr 1fr 1fr"]} gridGap={2}>
        <Controller
          control={control}
          name="roomTemperatureMeasuredAt"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Temperatuur vloer gemeten op"
            />
          )}
        />
        <FormInput
          label="Waarde is (C)"
          placeholder="Maak uw keuze"
          {...register("roomTemperatureValue")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("roomTemperatureImages")}
          defaultFilename="kamertemperatuur"
        />

        <Controller
          control={control}
          name="floorTemperatureMeasuredAt"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Temperatuur ruimte gemeten op"
            />
          )}
        />
        <FormInput
          label="Waarde is (C)"
          placeholder="Maak uw keuze"
          {...register("floorTemperatureValue")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("floorTemperatureImages")}
          defaultFilename="vloertemperatuur"
        />

        <Controller
          control={control}
          name="floorMoistureMeasuredAt"
          render={({ field }) => (
            <FormDate
              onChange={(date) => field.onChange(date)}
              selected={field.value}
              label="Vochtigheid vloer gemeten op"
            />
          )}
        />
        <FormInput
          label="Waarde is (%)"
          placeholder="Maak uw keuze"
          {...register("floorMoistureValue")}
        />
        <FormUpload
          label="Foto's toevoegen"
          {...getFormUploadProps("floorMoistureImages")}
          defaultFilename="vloervochtigheid"
        />
      </Grid>
      <Grid gridTemplateColumns={["1fr", "1fr 1fr"]} gridGap={2} my={2}>
        <FormTextarea
          label="Gespreksverslag (bijvoorbeeld met klant)"
          {...register("conversationLog")}
        />
        <FormTextarea label="Opmerking" {...register("workspaceComment")} />
      </Grid>
      <FormButtons
        onPrevious={handleSubmit((args) => onSubmit(args, -1))}
        onNext={handleSubmit((args) => onSubmit(args, 1))}
        onSave={handleSubmit((args) => onSubmit(args, 0))}
      />
    </form>
  );
};

export default IntakeWorkspaceForm;
