import styled from "styled-components";
import {
  variant,
  space,
  SpaceProps,
  layout,
  LayoutProps,
  color,
  ColorProps,
} from "styled-system";

interface Props extends SpaceProps, LayoutProps, ColorProps {
  variant?: string;
}

const Button = styled("button")<Props>(
  {
    border: 0,
    outline: 0,
    borderRadius: "1rem",
    fontWeight: "500",
    cursor: "pointer",
    fontSize: "1.4rem",
    height: "5rem",
    padding: "0 1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    svg: {
      marginRight: "1rem",
    },
  },
  variant({
    variants: {
      primary: {
        color: "white",
        bg: "primary",
        "&:hover": {
          bg: "primaryDark",
        },
        "&:active": {
          bg: "white",
        },
        "&:disabled": {
          bg: "disabled",
        },
      },
      secondary: {
        color: "main",
        bg: "white",
        border: "1px solid #ECEEEF",
        "&:hover": {
          borderColor: "primary",
        },
        "&:active": {
          backgroundColor: "primaryLight",
        },
      },
      tertiary: {
        backgroundColor: "#F2F3F3",
        "&:hover": {
          backgroundColor: "#ECEEEF",
        },
      },
      transparent: {
        color: "main",
        bg: "transparent",
        padding: 0,
        "&:hover": {
          textDecoration: "underline",
        },
      },
      icon: {
        padding: 0,
        width: 40,
        minWidth: 40,
        height: 40,
        borderRadius: 40,
        fill: "#B3BAD0",
        stroke: "#B3BAD0",
        svg: {
          marginRight: 0,
        },
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "#F3F6FA",
        },
        "&:active": {
          fill: "#2E79F1",
          stroke: "#2E79F1",
        },
      },
      empty: {
        all: "unset",
        outline: "none",
        border: "none",
        cursor: "pointer",
        svg: {
          marginRight: "0",
        },
      },
      active: {
        backgroundColor: "primaryLight",
        color: "primary",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "primary",
        svg: {
          marginRight: "1.5rem",
        },
        fontSize: 14,
      },
      upload: {
        color: "primary",
        padding: "1.5rem",
        fontSize: "2rem",
        svg: {
          marginRight: "1.5rem",
        },
        fontWeight: 400,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%231A45FFFF' stroke-width='2' stroke-dasharray='8%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      },
    },
  }),
  space,
  layout,
  color
);

export default Button;
