import React, { ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import { CenteredLabel, Checkbox } from "../../styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  mb?: number;
  checked?: boolean;
  value?: any;
  style?: object;
  onChange?: (e: any) => void;
}

const FormCheckbox: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, onChange, style, ...props },
  ref
) => {
  return (
    <CenteredLabel style={style}>
      <Checkbox
        onChange={(e: any) => {
          onChange?.(!e.target.value || e.target.value === "false");
        }}
        type="checkbox"
        ref={ref}
        checked={props.value}
        {...props}
      />
      {label}
    </CenteredLabel>
  );
};

export default React.forwardRef(FormCheckbox);
