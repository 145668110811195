const undergroundCheckFields = [
  { key: "newUnderground", value: "Nieuwe ondergrond" },
  { key: "existingFloors", value: "Bestaande ondergrond" },
  { key: "depositedOnSand", value: "Gestort op zand" },
  { key: "ventilatedCrawlSpace", value: "Geventileerde kruipruimte" },
  { key: "unventilatedCrawlSpace", value: "Niet geventileerde kruipruimte" },
  { key: "sandCement", value: "Zandcement" },
  { key: "anhydrite", value: "Anhydriet" },
  { key: "wood", value: "Hout" },
  { key: "tileFloor", value: "Tegelvloer" },
  { key: "monolith", value: "Monoliet" },
  { key: "curingCompound", value: "Curing compount" },
];

export default undergroundCheckFields;
