export default [
  { key: "destroySkirting", value: "Bestaande plinten slopen" },
  {
    key: "skirtingAttached",
    value: `Bestaande plinten met "voetje" zijn bevestigd op bestaande vloerbedekking`,
  },
  {
    key: "skirtingBelow",
    value: `Bestaande vloerafwerking is geplaats boven op de bestaande plint met "voetje"`,
  },
  {
    key: "skirtingOther",
    value: "Nieuwe plint plaatsen, namelijk (toelichting vullen)",
  },
];
