import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { SpaceProps } from "styled-system";
import { CheckBlockField, RoomTypes } from "../lib/types";
import { Block } from "../styles";
import FormCheckbox from "./form/FormCheckbox";

interface Props extends SpaceProps {
  name: CheckBlockField;
  label: string;
  value: boolean | undefined;
  control: Control<RoomTypes>;
}

const CheckBlock: FC<Props> = ({ name, value, label, control }) => {
  return (
    <Block
      bg={value ? "primaryLight" : ""}
      borderColor={value ? "primary" : ""}
      mb={1}
      my="auto"
      height="7rem"
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Controller
        name={name}
        control={control}
        render={(props) => {
          return (
            <FormCheckbox
              label={label}
              {...props.field}
              style={{ padding: "2rem", width: "100%" }}
            />
          );
        }}
      />
    </Block>
  );
};

export default CheckBlock;
