import styled from "styled-components";
import { devices } from "../lib/sizes";

const Checkbox = styled.input`
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 1rem;
  margin-top: 0 !important;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: relative;
  -o-appearance: none;
  border: 1px solid #eceeef;
  background-color: #fff;
  border-radius: 0.3rem;
  &:checked {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};

    &:before {
      display: inline-block;
      content: " ";
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.28571 9L0 4.67308L1.2 3.46154L4.28571 6.57692L10.8 0L12 1.21154L4.28571 9Z' fill='white'/%3E%3C/svg%3E");
      background-size: 1.2rem 0.9rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 1.2rem;
      height: 0.9rem;
    }
  }

  /* &[value="Nee"] {
    &:checked {
      background-color: ${({ theme }) => theme.colors.error};
      border-color: ${({ theme }) => theme.colors.error};
    }
  } */

  @media ${devices.mobile} {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
`;

export default Checkbox;
