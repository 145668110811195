import React, { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { RoomContext } from "../context/RoomContext";
import { firestore } from "../firebase/firebaseSetup";
import { RoomTypes } from "../lib/types";
import { Block, Box, Button, Text } from "../styles";
import { ReactComponent as EllipsisIcon } from "../assets/icon/ellipsis.svg";
import { ReactComponent as CrossIcon } from "../assets/icon/rounded-cross.svg";
import {
  RoomFormContainer,
  RoomUndergroundForm,
  RoomUndergroundPreparationForm,
  RoomCurtainForm,
} from ".";
import ApplyCoverForm from "./form/ApplyCoverForm";
import RoomWindowCoveringsForm from "./form/RoomWindowCoveringsForm";
import RoomSkirtingBoardsForm from "./form/RoomSkirtingBoadsForm";
import RoomDoorwaysForm from "./form/RoomDoorwaysForm";
import RoomOtherForm from "./form/RoomOtherForm";
import AbsoluteShadedBox from "../styles/AbsoluteFadedBox";
import Dropdown from "./Dropdown";
import UpdateRoomModal from "./UpdateRoomModal";
import { devices } from "../lib/sizes";

interface Props {
  active: boolean;
  room: RoomTypes;
  setRoomId: (roomId: string) => void;
  addRoomToState: (room: RoomTypes) => void;
  updateRoomFromState: (room: RoomTypes) => void;
  removeRoomFromState: (roomId: string) => void;
}

const RoomContent = styled(Button)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: min-content;
  display: flex;
  min-height: 7rem;
  width: 100%;

  @media ${devices.mobile} {
    min-height: 6rem;
  }
`;

const RoomFormsContainer = styled.div`
  > *:not(:first-child) {
    border-top: 1px solid #eceeef;
  }
`;

const RoomContainer = styled(Box)`
  margin-bottom: 1rem;
  height: min-content;
  background-color: #fff;

  @media ${devices.mobile} {
    position: ${({ active }: { active: boolean }) => (active ? "fixed" : "")};
    inset: 0;
    max-height: 100%;
    height: 100%;
    overflow: auto;
    z-index: ${({ active }: { active: boolean }) => (active ? 1000 : "")};
  }
`;

const Room: FC<Props> = ({
  room,
  setRoomId,
  active,
  addRoomToState,
  updateRoomFromState,
  removeRoomFromState,
}) => {
  const [showActions, setShowActions] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<number | null>(null);
  const [showUpdateRoomModal, setShowUpdateRoomModal] = useState<string | null>(
    null
  );

  const roomDetails = [
    {
      title: "Ondergrond",
      subTitle: "Meerdere opties mogelijk",
      form: <RoomUndergroundForm />,
      activeForm,
      setActiveForm,
      show: room.floors,
    },
    {
      title: "Uit te voeren handelingen tbv voorbereiding ondergrond",
      form: <RoomUndergroundPreparationForm />,
      activeForm,
      setActiveForm,
      show: room.floors,
    },
    {
      title: "Aanbrengen bedekking (optioneel)",
      form: <ApplyCoverForm />,
      activeForm,
      setActiveForm,
      show: room.floors,
    },
    {
      title: "Gordijnen",
      form: <RoomCurtainForm />,
      activeForm,
      setActiveForm,
      show: room.windows,
    },
    {
      title: "Raambekleding",
      form: <RoomWindowCoveringsForm />,
      activeForm,
      setActiveForm,
      show: room.windows,
    },
    {
      title: "Plinten",
      subTitle: "Meerdere opties mogelijk",
      form: <RoomSkirtingBoardsForm />,
      activeForm,
      setActiveForm,
      show: true,
    },
    {
      title: "Deuropeningen en dorpels",
      subTitle: "Meerdere opties mogelijk",
      form: <RoomDoorwaysForm />,
      activeForm,
      setActiveForm,
      show: true,
    },
    {
      title: "Overige",
      form: <RoomOtherForm />,
      activeForm,
      setActiveForm,
      show: room.other,
    },
  ].filter(({ show }) => show);
  const { id } = useParams();

  const saveRoom = async (args: RoomTypes) => {
    try {
      await firestore()
        .collection(`documents/${id}/rooms`)
        .doc(room.id)
        .set(
          { ...args, updatedAt: firestore.FieldValue.serverTimestamp() },
          { merge: true }
        );
      updateRoomFromState({ ...room, ...args });
      setActiveForm(activeForm ? activeForm + 1 : 1);
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };

  const value = useMemo(
    () => ({
      room,
      saveRoom,
    }),
    [room, activeForm]
  );

  const duplicateRoom = async () => {
    try {
      const newRoom = { ...room, name: `${room.name} (kopie)` };
      const { id: duplicateId }: { id: string } = await firestore()
        .collection(`documents/${id}/rooms`)
        .add(newRoom);
      addRoomToState({ ...newRoom, id: duplicateId });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const deleteRoom = async () => {
    try {
      await firestore()
        .collection(`documents/${id}/rooms`)
        .doc(room.id)
        .delete();
      removeRoomFromState(room.id);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const handleChange = (key: string) => {
    setShowActions(false);
    if (key === "DUPLICATE") return duplicateRoom();
    if (key === "DELETE") return deleteRoom();
    return setShowUpdateRoomModal(room.id);
  };

  const options = [
    { key: "DUPLICATE", value: "Ruimte dupliceren" },
    { key: "EDIT", value: "Ruimte wijzigen" },
    { key: "DELETE", value: "Ruimte verwijderen" },
  ];

  const onSuccess = (updatedRoom: RoomTypes) => {
    updateRoomFromState(updatedRoom);
    setShowUpdateRoomModal(null);
  };

  return (
    <RoomContext.Provider value={value}>
      <RoomContainer active={active}>
        <Box
          key={room.id}
          height={[active ? "100%" : "min-content", "min-content"]}
        >
          <Block
            px={2}
            height={[active ? "100%" : "min-content", "min-content"]}
          >
            <RoomContent
              variant="empty"
              onClick={() => setRoomId(active ? "" : room.id)}
            >
              <Text fontSize="1.4rem" fontWeight="500">
                Ruimte {room.name || ""}
              </Text>
            </RoomContent>
            {active && (
              <RoomFormsContainer>
                {roomDetails.map(({ form, ...roomDetail }, idx) => (
                  <RoomFormContainer
                    idx={idx}
                    key={roomDetail.title}
                    {...roomDetail}
                  >
                    {form}
                  </RoomFormContainer>
                ))}
              </RoomFormsContainer>
            )}
          </Block>
        </Box>
        <AbsoluteShadedBox top={["1rem", "1.5rem"]}>
          <Button
            variant="icon"
            onClick={() =>
              active && window.innerWidth < 500
                ? setRoomId("")
                : setShowActions((prev) => !prev)
            }
          >
            {active && window.innerWidth < 500 ? (
              <CrossIcon fill="#10182F" />
            ) : (
              <EllipsisIcon />
            )}
          </Button>
          <Dropdown
            show={showActions}
            onChange={handleChange}
            options={options}
            right="0px"
            hide={() => setShowActions(false)}
            left="auto"
          />
        </AbsoluteShadedBox>
      </RoomContainer>
      <UpdateRoomModal
        close={() => setShowUpdateRoomModal(null)}
        onSuccess={onSuccess}
        show={room.id === showUpdateRoomModal}
        room={room}
      />
    </RoomContext.Provider>
  );
};

export default Room;
