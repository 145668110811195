import React, { FC, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";
import { ClosedQuestionField, DocumentType } from "../lib/types";
import { Block, Box, Text } from "../styles";
import FormRadio from "./form/FormRadio";

interface Props {
  title: string;
  name: ClosedQuestionField;
  warning?: string;
  onFalse?: boolean;
  value: boolean | undefined;
  children?: ReactNode;
  control: Control<DocumentType>;
  onTrue?: ReactNode;
}

const ClosedQuestions: FC<Props> = ({
  title,
  name,
  value,
  warning,
  onFalse,
  children,
  control,
  onTrue,
}) => {
  const bg = value === false ? "primaryLight" : "white";
  const borderColor = value === false ? "primary" : "#ECEEEF";
  return (
    <Box mb={1}>
      <Block
        bg={value ? "primaryLight" : bg}
        height="7rem"
        borderColor={value ? "primary" : borderColor}
      >
        <Box flexDirection="row" alignItems="center" height="100%" px={2}>
          <Box minWidth="50%">
            <Text fontSize="1.4rem">{title}</Text>
          </Box>
          {warning && value ? (
            <Box>
              <Text fontSize={["0.8rem", "1.4rem"]} color="error">
                {warning}
              </Text>
            </Box>
          ) : null}
          <Box ml="auto">
            <Controller
              name={name}
              control={control}
              render={(props) => {
                return <FormRadio {...props.field} />;
              }}
            />
          </Box>
        </Box>
      </Block>
      {children && (onFalse ? value === false : value) ? (
        <Box m={["1rem 0 2rem 0", "2rem 0 4rem 0"]}>{children}</Box>
      ) : null}
      {onTrue && value ? (
        <Box m={["1rem 0 2rem 0", "2rem 0 4rem 0"]}>{onTrue}</Box>
      ) : null}
    </Box>
  );
};

export default ClosedQuestions;
