import React, { ForwardRefRenderFunction, InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Label, Textarea } from "../../styles";
import Warning from "../Warning";

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  ref?: string;
  error?: string;
  mb?: number;
}

const TextAreaContainer = styled.div`
  position: relative;
`;

const FormInput: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = ({ name, label, mb, error, ...otherProps }, ref) => {
  return (
    <Label htmlFor={name} mb={mb}>
      {label}
      <TextAreaContainer>
        <Textarea {...otherProps} name={name} ref={ref} />
        {error ? <Warning title={error} /> : null}
      </TextAreaContainer>
    </Label>
  );
};

export default React.forwardRef(FormInput);
