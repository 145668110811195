const roomKindOptions = [
  {
    kind: { key: "floors", value: "A. Vloeren" },
    options: [
      { key: "floorDemolish", value: "Slopen" },
      { key: "floorEqualize", value: "Egaliseren" },
      { key: "floorLay", value: "Leggen" },
    ],
  },
  {
    kind: { key: "windows", value: "B. Ramen" },
    options: [
      { key: "windowRails", value: "Rails" },
      { key: "windowCurtains", value: "Gordijnen" },
      { key: "windowNetCurtains", value: "Vitrages" },
      { key: "windowIndoorBlinds", value: "Binnenzonwering" },
    ],
  },
  {
    kind: { key: "other", value: "C. Overige" },
    options: [
      { key: "otherRails", value: "Plinten" },
      { key: "otherWall", value: "Wanden" },
      { key: "otherStairs", value: "Trappen" },
      { key: "otherComment", value: "Anders" },
    ],
  },
];

export default roomKindOptions;
