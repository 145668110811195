import React, { FC } from "react";
import styled from "styled-components";
import { Box, Button, Circle, Text } from "../styles";

interface Props {
  step: number;
  activeStep: number;
  title: string;
  onClick: (step: number) => any;
}

const NoWrapText = styled(Text)`
  white-space: nowrap;
`;

const Step: FC<Props> = ({ step, activeStep, title, onClick }) => {
  const bg = activeStep === step ? "#2E79F1" : "#DDE0E9";
  return (
    <Button variant="empty" onClick={() => onClick(step)}>
      <Box flexDirection="row" alignItems="center">
        <Circle
          bg={step < activeStep ? "#E8F1FF" : bg}
          color={step < activeStep ? "#2E79F1" : "#fff"}
        >
          {step}
        </Circle>
        {activeStep === step ? (
          <Box ml={1}>
            <NoWrapText color="primary" fontWeight="600" fontSize="1.2rem">
              Step {step}
            </NoWrapText>
            <NoWrapText fontSize="1.4rem" fontWeight="600">
              {title}
            </NoWrapText>
          </Box>
        ) : null}
      </Box>
    </Button>
  );
};

export default Step;
