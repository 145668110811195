import React, { FC, MouseEvent, useRef, ReactNode } from "react";
import styled from "styled-components";
import { Box, Button, Text } from "../../styles";
import { ReactComponent as ArrowUpIcon } from "../../assets/icon/arrow-up.svg";
import { devices } from "../../lib/sizes";

const FormRow = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: min-content;
  min-height: 7rem;

  @media ${devices.mobile} {
    min-height: 5rem;
    padding: 1rem 0;
  }
`;

const FormContainer = styled(Box)`
  svg {
    transition: all 0.5s ease;
  }
`;

interface Props {
  title: string;
  children: ReactNode;
  subTitle?: string;
  activeForm: number | null;
  setActiveForm: (prev: number | null) => void;
  idx: number;
}

const RoomFormContainer: FC<Props> = ({
  title,
  children,
  subTitle,
  activeForm,
  setActiveForm,
  idx,
}) => {
  const roomDetailRef: any = useRef(null);

  const handleActiveForm = (e: MouseEvent) => {
    e.stopPropagation();
    setActiveForm(idx === activeForm ? null : idx);
    setTimeout(() => {
      roomDetailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 200);
  };

  const isActive = activeForm === idx;

  return (
    <FormContainer>
      <Box height="min-content">
        <FormRow variant="empty" onClick={handleActiveForm}>
          <Box>
            <Text
              fontSize={["1.6rem", "2rem"]}
              fontWeight="600"
              ref={roomDetailRef}
              style={{ scrollMarginTop: window.innerWidth > 900 ? 100 : 80 }}
            >
              {title}
            </Text>
            {isActive && subTitle ? (
              <Text fontSize={["1.4rem", "1.6rem"]} color="disabled">
                {subTitle}
              </Text>
            ) : null}
          </Box>
          <ArrowUpIcon
            style={{
              transform: `rotate(${isActive ? "180" : "0"}deg)`,
              minWidth: 12,
            }}
          />
        </FormRow>
        {isActive && children}
      </Box>
    </FormContainer>
  );
};

export default RoomFormContainer;
