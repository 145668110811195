import React, { FC } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Button, Grid, Input, Label } from "../../styles";
import { ReactComponent as CrossIcon } from "../../assets/icon/cross.svg";
import FormDate from "./FormDate";

interface Props {
  name: string;
  control: any;
  register: any;
}

const FormFieldArray: FC<Props> = ({ name, control, register }) => {
  const {
    fields = [],
    append,
    remove,
  } = useFieldArray({
    control,
    name,
  });

  return (
    <>
      {fields.length ? (
        <Grid
          gridTemplateColumns={["1fr", "2fr 2fr 1fr 1fr"]}
          gridGap={2}
          mb="0.5rem"
        >
          <Label>Naam stoffeerder</Label>
          <Label>Datum</Label>
          <Label>Aantal uren</Label>
        </Grid>
      ) : null}
      <Grid
        gridTemplateColumns={["1fr", "2fr 2fr 1fr 1fr"]}
        gridGap={2}
        mb={fields.length ? 2 : 0}
      >
        {fields.map((field, index: number) => (
          <>
            <Input key={field.id} {...register(`${name}.${index}.name`)} />
            <Controller
              control={control}
              name={`${name}.${index}.date`}
              render={({ field: dateField }) => (
                <FormDate
                  onChange={(date) => dateField.onChange(date)}
                  selected={dateField.value}
                />
              )}
            />
            <Input key={field.id} {...register(`${name}.${index}.hours`)} />
            <Button variant="icon" onClick={() => remove(index)}>
              <CrossIcon fill="#10182F" />
            </Button>
          </>
        ))}
      </Grid>
      <Button
        variant="secondary"
        onClick={() => append({})}
        width={["100%", "200px"]}
      >
        Stoffeerder toevoegen
      </Button>
    </>
  );
};

export default FormFieldArray;
